import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "../../../assets/vizr_images/ic_remove.svg";
import Button from "@material-ui/core/Button";
import { CreateDataSource } from "../../views/CreateDataSource";
import {
  CLEAR_DATA_SOURCE_DETAILS,
  CLEAR_SOURCE_SAVED,
  ENTRA,
  GOOGLEDRIVE,
  MSSQL,
  POSTGRESQL,
  UPDATE_DATASOURCE_SUCCESS,
  cloudFileFormTextMap,
  sourceDetailsHeaderTitles,
} from "../../../redux/constants/constants";
import StaticPlatformInstructions from "./StaticPlatformInstructions";
import arrow_back_ios from "../../../assets/icons/arrow_back_ios.svg";
import { CreateLogSource } from "../logSource/CreateLogSource";
import { isCloudFileSystem } from "../../common/utilities";

function ManageDataSource({
  toggleDrawer,
  getAllDataSources,
  isEditMode,
  isAliasEdit,
  stateOpen,
  classes,
  dispatch,
}) {
  const [staticInstructionsOpen, setStaticInstructionsOpen] = useState(false);
  const [sourceType, setSourceType] = useState("");
  const [loadDataSourceData, setLoadDataSourceData] = useState(false);
  const [logSourceOpen, setLogSourceOpen] = useState(false);

  const handleClose = (event) => {
    if (isEditMode) {
      dispatch({type: CLEAR_SOURCE_SAVED});
    }
    dispatch({
      type: UPDATE_DATASOURCE_SUCCESS,
      payload: null,
    });
    dispatch({ type: CLEAR_DATA_SOURCE_DETAILS });
    toggleDrawer(false, event);
  };

  const renderAddFormText = () => {
    if (sourceType === ENTRA) {
      return cloudFileFormTextMap[sourceType];
    } else {
      return cloudFileFormTextMap.default;
    }
  };

  //Renders the appropriate Form title based on the current conditions.
  const renderCloudFileFormText = () => {
    const text = isCloudFileSystem(sourceType) ? cloudFileFormTextMap[sourceType] : cloudFileFormTextMap.default;
    return isEditMode ? `Edit ${text}` : `Add ${renderAddFormText()}`;
  };
  

  const handleCloseScreen = (location) => {
    if (location === "winlogbeat") {
      setStaticInstructionsOpen(false);
      setLoadDataSourceData(true);
    }
    if (location === "logSource") {
      setLogSourceOpen(false);
      setLoadDataSourceData(true);
    }
  };

  const winlogbeatTitle = () => {
    return (
      <>
        <div className="winlogbeatTitleContainer">
          <img
            src={arrow_back_ios}
            alt="back"
            onClick={() => handleCloseScreen("winlogbeat")}
            style={{ width: "30px" }}
          />
        </div>
        <div className="winlogbeatHeadingTextContainer">
          <div>Add Data Source</div>
          <div className="winlogbeatTitleText">{sourceDetailsHeaderTitles[sourceType]}</div>
        </div>
      </>
    );
  };

  const addLogSourceTitle = () => {
    return (
      <>
        <div className="winlogbeatTitleContainer">
          <img
            src={arrow_back_ios}
            alt="back"
            onClick={() => handleCloseScreen("logSource")}
            style={{ width: "30px" }}
          />
        </div>
        <div className="addLogSourceText">
          <div>Add Log Source</div>
        </div>
      </>
    );
  };

  const renderTitle = () => {
    if (staticInstructionsOpen) {
      return winlogbeatTitle();
    } else if (logSourceOpen) {
      return addLogSourceTitle();
    } else {
      return renderCloudFileFormText();
    }
  };

  const renderClassNames = () => {
    if (staticInstructionsOpen) {
      return "access_Details winlogbeatTitleTextContainer";
    } else if (logSourceOpen) {
      return "access_Details logSourceTitleTextContainer";
    } else {
      return "access_Details";
    }
  };

  const renderCloseButtonClassNames = () => {
    if (staticInstructionsOpen) {
      return "winlogbeatCloseButton";
    } else {
      return "dataSourceCloseButton";
    }
  };

  const renderCreateDataSource = () => {
    return (
      <>
        <div
          style={{
            width: "650px",
            padding: "30px 30px",
            boxSizing: "border-box",
          }}
        >
          <div className="data-source-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className={renderClassNames()}>{renderTitle()}</div>
              <div className={renderCloseButtonClassNames()}>
                <Button
                  onClick={(event) => handleClose(event)}
                  aria-label="close"
                >
                  <img src={CloseIcon} alt={"close"} />
                </Button>
              </div>
            </div>
            {renderSidePanel()}
          </div>
        </div>
      </>
    );
  };

  const renderSidePanel = () => {
    if (staticInstructionsOpen) {
      return (
        <>
          <StaticPlatformInstructions sourceType={sourceType} />
        </>
      );
    } else if (logSourceOpen) {
      return (
        <CreateLogSource
          toggleDrawer={toggleDrawer}
          setLoadDataSourceData={setLoadDataSourceData}
          setLogSourceOpen={setLogSourceOpen}
          loc="dataSource"
        />
      );
    } else {
      return (
        <CreateDataSource
          toggleDrawer={toggleDrawer}
          isEditMode={isEditMode}
          getAllDataSources={getAllDataSources}
          isAliasEdit={isAliasEdit}
          staticInstructionsOpen={setStaticInstructionsOpen}
          setSourceType={setSourceType}
          logSourceOpen={setLogSourceOpen}
          loadDataSourceData={loadDataSourceData}
          sourceType={setSourceType}
        />
      );
    }
  };

  return (
    <React.Fragment>
      <Drawer
        classes={{
          paper: classes.paper,
        }}
        anchor={"right"}
        open={stateOpen}
        onClose={(event) => handleClose(event)}
      >
        {renderCreateDataSource()}
      </Drawer>
    </React.Fragment>
  );
}

export default ManageDataSource;
