import React, { useEffect, useState } from "react";
import ContentPage from "../Components/ContentPage";
import SysGuardHeader from "../../SysGuardHeader";
import DataTable from "../Components/DataTable";
import { tableData } from "../../../../redux/constants/constants";
import Page_Loader from "../../../../assets/vizr_images/page_loader.gif";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_SYS_GUARD_DATA_SOURCE, GET_SYS_GUARD_APPLICATION, GET_SYS_GUARD_LOCATION, GET_SYS_GUARD_USERS, GET_SYS_GUARD_HEADER_DATA } from "../../sysGuardConstants";
import { isNull } from "lodash";
import ManagePopup from "../../Popups/ManagePopup";

function SysGuardContent(props) {
  const selectedCategory = props.match.params.name;
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100); //Just for this release making default size to 100 (02042025)
  const [popupDetails, setPopupDetails] = useState({});
  const [popupOpen, setPopupOpen] = useState(false);
  const loader = useSelector((state) => state.SysGuardReducer.loader);
  const setSelectedCategory = (selected) => {
    props.history.push(`/mdr/sysguard/content/${selected}`);
  };
  const sysGuardData = useSelector(
    (state) => state.SysGuardReducer.sysGuardDataSource
  );
  const fromTimeStamp = useSelector(
    (state) => state.SysGuardReducer.fromTimeStamp
  );
  const toTimeStamp = useSelector(
    (state) => state.SysGuardReducer.toTimeStamp
  );
  const tableData = useSelector(
    (state) => state.SysGuardReducer.sysGuardTableData
  );
  const totalElements = useSelector(
    (state) => state.SysGuardReducer.totalElements
  );
  const isIncognito = useSelector(
    (state) => state.SysGuardReducer.incognito
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if(isNull(sysGuardData)){
      dispatch({ type: FETCH_SYS_GUARD_DATA_SOURCE, payload: "ENTRA" });
    }
  },[])

  useEffect(() => {
    if (!isNull(sysGuardData)) {
      const id = sysGuardData.id;
      const actionTypes = {
        Locations: GET_SYS_GUARD_LOCATION,
        Users: GET_SYS_GUARD_USERS,
        Applications: GET_SYS_GUARD_APPLICATION,
      };
      dispatch({type: GET_SYS_GUARD_HEADER_DATA});
      if (actionTypes[selectedCategory]) {
        dispatch({
          type: actionTypes[selectedCategory],
          payload: { currentPage, pageSize, id, isIncognito },
        });
      }
    }
  }, [selectedCategory, fromTimeStamp, toTimeStamp, currentPage, pageSize, sysGuardData, isIncognito]);
  

  return (
    <div>
      {loader && (
        <img src={Page_Loader} alt="loading" className="sysGuardLoader"></img>
      )}
      <div className="sys-guard guard-content">
        <SysGuardHeader />
        <ContentPage
          selected={selectedCategory}
          setSelected={setSelectedCategory}
        />
      </div>
      <div className="sys-guard-data-table">
        <DataTable
          data={tableData}
          totalElements={totalElements}
          type={selectedCategory}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPopupDetails={setPopupDetails}
          setPopupOpen={setPopupOpen}
        />
      </div>
      <ManagePopup popupDetails={popupDetails} popupOpen={popupOpen} setPopupOpen={setPopupOpen} />
    </div>
  );
}

export default SysGuardContent;
