import { isNull } from "lodash";
import React from "react";
import { maskedUsernameLoc } from "../../sysGuardConstants";
import { useSelector } from "react-redux";
import { maskUsername } from "../../SysGuardCommonComp";

const SysGuardInfoBox = ({ data, loc }) => {
  const isIncognito = useSelector((state) => state.SysGuardReducer.incognito);
  const alertInfo = data?.alertInfo;
  const ipInfo = data?.clientIp;
  const userNameInfo =
    isIncognito && maskedUsernameLoc.includes(loc)
      ? maskUsername(data?.userName)
      : data?.userName;

  return (
    <div className="sys-guard-info-box">
      <h2>Alert Details</h2>
      {!isNull(alertInfo) && alertInfo ? (
        <div className="parent-container">
          {loc === "locationBasedAnomalies" && (
            <div className="child-container-two">
              <div className="child-container">
                <div className="para-name">User Name:</div>
                <div>{userNameInfo}</div>
              </div>
              <div className="child-container">
                <div className="para-name">IP Address:</div>
                <div>{ipInfo}</div>
              </div>
            </div>
          )}
          <div className="child-container">
            <div className="para-name">Alert Information:</div>
            <div>{alertInfo}</div>
          </div>
        </div>
      ) : (
        <div>Select a row to view alert information.</div>
      )}
    </div>
  );
};

export default SysGuardInfoBox;
