import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import { customBarChatLocations, unknownUser } from "../../sysGuardConstants";

// const data = [
//   { name: "App-1", value: 5000 },
//   { name: "App-2", value: 9000 },
//   { name: "App-3", value: 3000 },
//   { name: "App-4", value: 12000 },
//   { name: "App-5", value: 15000 },
//   { name: "App-6", value: 19000 },
//   { name: "App-1", value: 30000 }, // Duplicate name for formatting like image
//   { name: "App-7", value: 21000 },
//   { name: "App-8", value: 16000 },
//   { name: "App-9", value: 10000 },
//   { name: "App-10", value: 3000 },
//   { name: "App-10", value: 5000 }, // Duplicate name for formatting like image
// ];

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, loc }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {!customBarChatLocations.includes(loc) ? (
          <p>{`${payload[0].payload.name} has ${(
            payload[0].value / 1000
          ).toFixed(0)} alerts`}</p>
        ) : (
          <p>{`The ${payload[0].payload.name} has usage frequency of ${(
            payload[0].value
          ).toFixed(0)}`}</p>
        )}
      </div>
    );
  }
  return null;
};

const CustomBarChart = ({applications, loc}) => {
  let data = applications.map((app) => {
    const appName = app.appDetails || app.name; // Use appDetails if available, else use name
    const abbr = appName
      ? appName
          .split(" ")
          .map((word) => word[0])
          .join("")
          .toUpperCase()
      : "Unknown";

    return {
      name: unknownUser(appName),
      abbr: abbr,
      value: app.alertCount || app.count,
    };
  });

   data = [...data].sort((a, b) => b.value - a.value).slice(0, 4);
  return (
    <div className="chart-container-sysguard">
      <h3 className="chart-title">Applications</h3>
      <ResponsiveContainer width="100%" height={!customBarChatLocations.includes(loc) ? 118 :200}>
        <BarChart data={data} barCategoryGap="15%">
          <XAxis
            dataKey="abbr"
            tick={{ fill: "#6787F2", fontSize: 12 }}
            tickLine={false}
          />
          <YAxis hide />
          <Tooltip content={<CustomTooltip loc={loc}/>} />
          <Bar
            dataKey="value"
            fill="url(#gradient)"
            radius={[20, 20, 0, 0]}
            fillOpacity={1} // Prevents dark overlay on hover
            className="custom-bar"
          />
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#3366FF" />
              <stop offset="100%" stopColor="#A2C8FF" />
            </linearGradient>
          </defs>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomBarChart;
