import { all } from "redux-saga/effects";

import selfSignUppageSaga from "./selfSignUppageSaga";
import deletePolicySaga from "./deletePolicySaga";
import updateAlertPolicySaga from "./updateAlertPolicySaga";
import updateAlertPolicyStatusSaga from "./updatePolicyStatusSaga";
import datasourceSaga from "./datasourceSaga";
import createAlertPolicySaga from "./createAlertPolicySaga";
import changeAlertStatusSaga from "./changeAlertStatusSaga";
import signInSaga from "./signInSaga";
import allAccessSaga from "./allAccessSaga";
import setPasswordSaga from "./setPasswordSaga";
import { TenantSignUpSaga } from "./TenantSignUpSaga";
import PlatformAdminTenantOnboardSaga from './platformAdminTenantOnBoardSaga';
import PlatformAdminTenantUpdateSaga from './platformAdminTenantUpdateSaga';
import deleteTenantSaga from './deleteTenantSaga';
import fetchUserInformationSaga from './onRefreshPermissionsSaga';
import updateSensitivityInfoSaga from './editSensitivitySaga';
import databaseLogsSaga from './databaseLogsSaga';
import normalDataSourceSaga from './normalDataSourceSaga';
import InspectContentSaga from "./inspectContentSaga";
import normalDirectDataSourceSaga from "./normalDirectDataSourceSaga";
import ServiceDataAccessSaga from "./serviceDataAccessSaga";
import serviceLevelAccessLogs from "./serviceAccessLogsSaga";
import dataMapSaga from "./dataMapSaga";
import dataMapUserSaga from "./dataMapUsersSaga";
import heatmapSaga from "./heatmapSaga";
import DataOwnerSaga from "./dataOwnersaga";
import TotalAssetsCountSaga from "./totalAssetssaga";
import generateReportSaga from "./generateReportSaga";
import DeleteConnectionSaga from "./DeleteConnectionSaga";
import TenantProfileSaga from "./TenantProfileSaga";
import NetSpaceSaga from "../../features/NetSpace/NetSpaceSaga";
import createSourceSaga from "./createSourceSaga";
import riskPilotSaga from "./riskPilotSaga";
import ntfsDataSourceSaga from "../../components/views/NTFS/ntfsDataSourceSaga";
import locationSaga from "../../components/views/manageDataMap/locationSaga";
import SysGuardSaga from "../../features/SysGuard/saga/SysGuardSaga";

export default function* rootSaga() {
  yield all([
        selfSignUppageSaga(), 
        signInSaga(),
        setPasswordSaga(), 
        TenantSignUpSaga(),
        allAccessSaga(),
        deletePolicySaga(),
        updateAlertPolicySaga(),
        datasourceSaga(),
        updateAlertPolicyStatusSaga(),
        createAlertPolicySaga(),
        createSourceSaga(),
        changeAlertStatusSaga(),
        PlatformAdminTenantOnboardSaga(),
        PlatformAdminTenantUpdateSaga(),
        deleteTenantSaga(),
        fetchUserInformationSaga(),
        updateSensitivityInfoSaga(),
        databaseLogsSaga(),
        normalDataSourceSaga(),
        normalDirectDataSourceSaga(),
        ServiceDataAccessSaga(),
        serviceLevelAccessLogs(),
        dataMapSaga(),
        dataMapUserSaga(),
        DeleteConnectionSaga(),
        heatmapSaga(),
        DataOwnerSaga(),
        TotalAssetsCountSaga(),
        generateReportSaga(),
        InspectContentSaga(),
        TenantProfileSaga(),
        NetSpaceSaga(),
        riskPilotSaga(),
        ntfsDataSourceSaga(),
        locationSaga(),
        SysGuardSaga(),
      ]);
}
