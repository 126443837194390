import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CLEAR_SYS_GUARD_TABLE_DATA } from "../../sysGuardConstants";

function SidebarMenu({ onSelect, selected }) {
  const dispatch = useDispatch();

  const menuItems = [
    { name: "Users", icon: "/sysGuardIcons/userIcon.svg" },
    { name: "Locations", icon: "/sysGuardIcons/place.svg" },
    { name: "Applications", icon: "/sysGuardIcons/settingsIcon.svg" },
  ];

  return (
    <div className="sidebar-container">
      {menuItems.map((item) => (
        <div
          key={item.name}
          className={`menu-item ${
            selected === item.name ? `${item.name} active` : `${item.name}`
          }`}
          onClick={() => {
            onSelect(item.name);
            dispatch ({ type: CLEAR_SYS_GUARD_TABLE_DATA});
          }}
        >
          <img src={item.icon} alt={item.name} />
          <span>{item.name}</span>
        </div>
      ))}
    </div>
  );
}

export default SidebarMenu;
