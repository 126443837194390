import LevelComponent from "../levelComponent";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Tooltip from "@material-ui/core/Tooltip";
import { DeleteButtonForToolBar } from "../../common/buttons";
import { getTime, isInactivityPolicy, renderDataSource } from "../../common/utilities";
import ViewDatabase from "../../../assets/icons/viewDatabase.svg";
import ViewDatabaseDisabled from "../../../assets/icons/viewDatabaseDisabled.svg";
import { withStyles } from "@material-ui/core/styles";
import ViewTooltip from "@material-ui/core/Tooltip";
import { renderDateTime } from "../../views/Util/ViewCommon";
import { viewAlertPolicy, viewDatabaseLogs } from "../../../redux/constants/constants";
import CanAllow from "../CanAllow";


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  arrow: {
    fontSize: 16,
    width: 17,
    "&::before": {
      border: "1px solid #fff",
      backgroundColor: "white",
      boxSizing: "border-box",
      boxShadow: " 0 0 2px #0004",
    },
  },
  disbaled:{
    opacity:"0.5"
  }
}))(ViewTooltip);

const avatarThumbnails = (rowData) => {
  return (
    <AvatarGroup className="avatar-root" max={4}>
      {rowData.monitors && rowData.monitors !== null
        ? rowData.monitors.map((each, index) => {
            let temp = each.monitorThumbnail;
            return (
              !each.muted && (
                <Tooltip title={temp.name}>
                  <Avatar
                    className="avatar"
                    id={index}
                    alt={temp.name}
                    elevation={4}
                    src={`data:image/png;base64, ${temp.picture}`}
                  />
                </Tooltip>
              )
            );
          })
        : rowData.alertPolicy && rowData.alertPolicy.monitors !== null
        ? rowData.alertPolicy.monitors.map((each, index) => {
            let temp = each.monitorThumbnail;
            return (
              !each.muted && (
                <Tooltip title={temp.name}>
                  <Avatar
                    className="avatar"
                    id={index}
                    alt={temp.name}
                    elevation={4}
                    src={`data:image/png;base64, ${temp.picture}`}
                  />
                </Tooltip>
              )
            );
          })
        : rowData.alertPolicy && rowData.alertPolicy.monitors !== null
        ? rowData.alertPolicy.monitors.map((each, index) => {
            let temp = each.monitorThumbnail;
            return (
              !each.muted && (
                <Tooltip title={temp.name}>
                  <Avatar
                    className="avatar"
                    id={index}
                    alt={temp.name}
                    elevation={4}
                    src={temp.picture}
                  />
                </Tooltip>
              )
            );
          })
        : ""}
    </AvatarGroup>
  );
};
export const displayName = (name) => {
  return (
    <span>{name}</span>
  )
}
export const filterUserForInactivityPolicy = (rowData, history, dispatch) => {
  if (
    rowData &&
    rowData.enterpriseActor &&
    rowData.enterpriseActor.username &&
    rowData.alertPolicy &&
    isInactivityPolicy(rowData.alertPolicy.type)
  ) {
    history.push(
      `/tenant/useraccessRights`
    );
    dispatch({
      type: 'UPDATE_SEARCH_VALUE',
      payload: rowData.enterpriseActor.username,
    });
  }
};
const inactivityPolicyUsername = (rowData) => {
  if(
    rowData.alertPolicy &&
    isInactivityPolicy(rowData.alertPolicy.type)
  ){
    return "inactivityPolicyUsername"
  }
}
export const tableConstants = (toggleDrawer, getTime, dispatch, history) => {
  const featuresData = JSON.parse(localStorage.getItem("featuresInfo"));
const features =
  featuresData &&
  featuresData.features &&
  featuresData.features.filter((data) => data.name === "Data Vision");
  
  let featuresEnabled =   
   features &&
  features.length &&
  features[0].licenseType.name !== "Platinum"
  // features[0].licenseType.name !== "Silver"
  return [
    {
      title: "Severity Level",
      orderId: "alertPolicy.severity",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return (
          <LevelComponent level={rowData.severity.toUpperCase()} />
        );
      },
    },
    {
      title: "Time",
      orderId: "generatedOn",
      style: { width: "15%" },
      rowBodyCellStyle: { width: "15%" , whiteSpace:"nowrap"},
      render: (rowData) => {
        return (
          <span
            className="mousePointer"
            onClick={(event) => {
              toggleDrawer(true, event, rowData.id);
            }}
          >
            {renderDateTime(rowData.generatedOn)}
          </span>
        );
      },
    },
    {
      title: "Policy Name",
      orderId: "alertPolicy.name",
      style: { width: "13%" },
      rowBodyCellStyle: { width: "13%" },
      render: (rowData) => {
        return (
          <CanAllow
            needs={{ permission: [viewAlertPolicy] }}
            component={displayName(rowData.alertPolicy.name)}
          >
            <span
              style={{ color: '#081981' }}
              onClick={() => {
                dispatch({
                  type: 'TRACK_TRAVERSAL',
                  payload: `Alert Policies - ${rowData.alertPolicy.name}`,
                });
                history.push(`/tenant/alertPolicies/${rowData.alertPolicy.id}`);
              }}
              className="mousePointer"
            >
              {rowData.alertPolicy.name}
            </span>
          </CanAllow>
        );
      },
    },
    {
      title: "Alert Violation",
      orderId: "type",
      style: { width: "15%" },
      rowBodyCellStyle: { width: "15%" },
      render: (rowData) => {
        const violation_text = rowData.alertViolationTxt;
        if (violation_text) {
          return <span>{violation_text}</span>;
        } else {
          return <span>{rowData.type ? rowData.type : ""}</span>;
        }
      },
    },
    {
      title: "Alert Violation Details",
      orderId: "reason",
      style: { width: "15%" },
      rowBodyCellStyle: { width: "15%" },
      render: (rowData) => {
        return <span>{rowData.reason ? rowData.reason : ""}</span>;
      },
    },
    {
      title: "Data Source Name",
      orderId: "dataSource.aliasName",
      style: { width: "16%" },
      rowBodyCellStyle: { width: "16%", wordBreak: "break-all" },
      render: (rowData) => {
        const dataSourceType = rowData.dataSource.type ? rowData.dataSource.type.code : "";
        const dataSourceName = (rowData.dataSource && rowData.dataSource.aliasName) || "";
        return (
          <span>
            {renderDataSource(dataSourceType, dataSourceName)}
          </span>
        );
      },
    },
    {
      title: "Username",
      orderId: "enterpriseActor.username",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" , wordBreak: "break-word"},
      render: (rowData) => {
        return (
          <span
            onClick={() => {
              filterUserForInactivityPolicy(rowData, history, dispatch);
            }}
            className={inactivityPolicyUsername(rowData)}
          >
            {(rowData.enterpriseActor && rowData.enterpriseActor.username) ||
              ""}
          </span>
        );
      },
    },
    {
      title: "Monitors",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{avatarThumbnails(rowData)}</span>;
      },
    },
    {
      title: "Action",
      style: { width: "5%" },
      rowBodyCellStyle: { width: "5%" },
      render: (rowData) => {
        return (
          
          rowData.alertPolicy.type.code === "SERVICE_LEVEL_ACCESS" ?
          <DeleteButtonForToolBar
            variant="contained"
            id="delete"
            size="small"
            color="primary"
            onClick={() => {
              history.push({
                pathname: "/tenant/databaseLogs",
                state: {
                  alertId: rowData.id,
                  configuration: rowData.configuration,
                  tag:"serviceLogs",
                
                },
              });
            }}
            className={featuresEnabled === true || rowData.alertPolicy.name === "Unusual Access Path" ? "disbaled" : ""}
            style={featuresEnabled === true || rowData.alertPolicy.name === "Unusual Access Path" ? {opacity:0.5 , cursor :"not-allowed"}:{}}
            disbaled={ featuresEnabled === true || rowData.alertPolicy.name === "Unusual Access Path" ? true : false}
          >
            <LightTooltip title={"View Service Access Logs"} arrow>
              <img src={ViewDatabase} alt="view-database-logs" />
            </LightTooltip>
          </DeleteButtonForToolBar>
         : 
          <div
            className={
              isInactivityPolicy(rowData.alertPolicy.type)
                ? "alertsTableDisable"
                : ""
            }
          >
            <DeleteButtonForToolBar
              variant="contained"
              id="delete"
              size="small"
              color="primary"
            className="min-margin"
              onClick={() => {
                history.push({
                  pathname: "/tenant/databaseLogs",
                  state: {
                    alertId: rowData.id,
                    configuration: rowData.configuration,
                  tag:"directDbLogs",
                 
                  },
                });
              }}
              disabled={isInactivityPolicy(rowData.alertPolicy.type)}
            >
              <LightTooltip title={"View Data Access Logs"} arrow>
                <img
                  src={
                    isInactivityPolicy(rowData.alertPolicy.type)
                      ? ViewDatabaseDisabled
                      : ViewDatabase
                  }
                  alt="view-data-access-logs"
                />
              </LightTooltip>
            </DeleteButtonForToolBar>
          </div>
        );
      },
      requiredPermission: viewDatabaseLogs
    },
  ];
};

export const relatedTableConstants = () => {
  return [
    {
      title: "Level",
      render: (rowData) => {
        return (
          <LevelComponent level={rowData.severity.toUpperCase()} />
        );
      },
    },
    {
      title: "Time",
      render: (rowData) => {
        return <span>{getTime(rowData.generatedOn)}</span>;
      },
    },
    {
      title: "Policy",
      render: (rowData) => {
        return <span>{rowData.alertPolicy.name}</span>;
      },
    },
  ];
};
