import React, { useEffect, useState } from "react";
import "./SysGuard.scss";
import NoDataAvailablePage from "./OnBoarding/NoDataAvailablePage";
import { GET_DATASOURCE } from "../../redux/constants/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNull } from "lodash";
import {
  FETCH_SYS_GUARD_DATA_SOURCE,
  statusMapping,
} from "./sysGuardConstants";
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import SysGuardIncognito from "./SysGuardIncognito";

const SysGuard = ({ source }) => {
  const currentRoute = useLocation();
  const dispatch = useDispatch();
  const dataSourceData = useSelector(
    (state) => state.SysGuardReducer.sysGuardDataSource
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [openOnBoardingForm, setOpenOnBoardingForm] = useState(false);
  const loader = useSelector((state) => state.SysGuardReducer.loader);

  useEffect(() => {
    if (currentRoute.search !== "") {
      const urlParam = new URLSearchParams(currentRoute.search);
      const stateParam = urlParam.get("state");
      const code = urlParam.get("code");

      if (stateParam && code) {
        const state = stateParam.includes("_")
          ? stateParam.split("_")
          : [stateParam, null];
        let dataSourceID = state[0];
        let consentType = state[1] || null;

        if (consentType) {
          dispatch({
            type: GET_DATASOURCE,
            payload: {
              dataSourceID,
              consentType,
            },
          });
        }
      }
    }
    dispatch({ type: FETCH_SYS_GUARD_DATA_SOURCE, payload: "ENTRA" });
  }, []);

  return (
    <div className="sys-guard">
      {loader && (
        <img src={Page_Loader} alt="loading" className="sysGuardLoader"></img>
      )}
      <div className="createRRHeader sysGuard">
        <div className="riskGovernanceHeaderContainer">
          <div>
            <h2 className="sysGuardHeaderTitle">SysGuard Dashboard</h2>
          </div>
          {!isEmpty(dataSourceData?.connectionStatus) &&
          !isNull(dataSourceData?.connectionStatus) ? (
            <div className="sys-guard-connection-status-container">
              <div className="sys-guard-connection-text">Access Log Source</div>
              <div className="sys-guard-status">
                <img
                  src={statusMapping[dataSourceData?.connectionStatus]}
                  onClick={() => {
                    setIsEditMode(true);
                    setOpenOnBoardingForm(true);
                  }}
                />
              </div>
              <SysGuardIncognito/>
            </div>
          ) : null}
        </div>
      </div>
      <NoDataAvailablePage
        source={source}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        openOnBoardingForm={openOnBoardingForm}
        setOpenOnBoardingForm={setOpenOnBoardingForm}
      />
    </div>
  );
};

export default SysGuard;