import React, { useState } from "react";
import GaugeChart from "./GaugeChart";
import SysGuardLocationMap from "./SysGuardLocationMap";
import CustomBarChart from "./CustomBarChart";
import LocationContent from "../ContentPages/SysGyardContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { calculatePercentage } from "../../sysGuardConstants";
import { useSelector } from "react-redux";
import UsersHeatMap from "./UsersHeatMap";
import Popup from "reactjs-popup";

function VerticalCard() {
  const history = useHistory();
  const sysGuardHeaderData = useSelector(
    (state) => state.SysGuardReducer.sysGuardHeaderData
  );
  const applications = sysGuardHeaderData?.applicationChart
    ? sysGuardHeaderData?.applicationChart
    : [];
  const location = sysGuardHeaderData?.location;
  const suspicious = sysGuardHeaderData?.suspicious;
  const application = sysGuardHeaderData?.application;
  const [isExpanded, setIsExpanded] = useState(false);
  const cardData = [
    {
      percentage: parseFloat(
        calculatePercentage(suspicious?.alertCount, suspicious?.totalCount)
      ),
      text1: "No Of Users:",
      value1: suspicious?.totalCount,
      text2: "User with Alerts:",
      value2: suspicious?.alertCount,
      img: "/sysGuardIcons/userIcon.svg",
      altText: "User Icon",
      background: "users",
      gaugeColor: "#8dc63f",
      name: "Users",
    },
    {
      percentage: parseFloat(
        calculatePercentage(location?.alertCount, location?.totalCount)
      ),
      text1: "No Of Locations:",
      value1: location?.totalCount,
      text2: "Location with Alerts:",
      value2: location?.alertCount,
      img: "/sysGuardIcons/place.svg",
      altText: "Location Icon",
      background: "location",
      gaugeColor: "#8dc63f",
      name: "Locations",
    },
    {
      percentage: parseFloat(
        calculatePercentage(application?.alertCount, application?.totalCount)
      ),
      text1: "No Of Applications:",
      value1: application?.totalCount,
      text2: "Application with Alerts:",
      value2: application?.alertCount,
      img: "/sysGuardIcons/settingsIcon.svg",
      altText: "Settings Icon",
      background: "applications",
      gaugeColor: "#8dc63f",
      name: "Applications",
    },
  ];

  const renderSubCharts = (name) => {
    if (name === "Locations") {
      return (
        <div onClick={() => setIsExpanded(true)}>
          <SysGuardLocationMap />
        </div>
      );
    } else if (name === "Applications") {
      return <CustomBarChart applications={applications} />;
    } else if (name === "Users") {
      return <UsersHeatMap />;
    }
  };

  const handleOnClick = (name) => {
    history.push(`/mdr/sysguard/content/${name}`);
  };

  return (
    <div>
      <div className="vertical-card-parent-container">
        {cardData.map((c, index) => (
          <div
            key={index}
            className={`sys-guard-card-container ${c.background}`}
          >
            <div className="sys-guard-vertical-card-container">
              <div
                className="sys-guard-vertical-card"
                onClick={() => handleOnClick(c.name)}
              >
                <GaugeChart value={c.percentage} gaugeColor={c.gaugeColor} />
                <div className="no-of-locations">
                  {c.text2} <span className="location-text">{c.value2}</span>
                </div>
                <div className="no-of-locations">
                  {c.text1} <span className="number-text">{c.value1}</span>
                </div>
                <div className="icon-text-container">
                  <div>
                    <img src={c.img} alt={c.altText} />
                  </div>
                  <div className="icon-text">{c.name}</div>
                </div>
              </div>
              <div className={`sys-guard-card ${c.background}`}>
                <div className="sys-guard-card-content">
                  {renderSubCharts(c.name)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={isExpanded ? "sys-guard-backdrop" : ""}>
        <Popup
          open={isExpanded}
          onClose={() => setIsExpanded(false)}
          modal
          contentStyle={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isExpanded ? "scale(1)" : "scale(0.9)",
            opacity: isExpanded ? "1" : "0",
          }}
        >
          <div className="sys-guard-dashboard-popup-container">
            <SysGuardLocationMap loc="dashboardPopup" />
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default VerticalCard;
