import {
  DROPBOX_CONNECT,
  ENTRA,
  GOOGLEDRIVE_CONNECT,
  MICROSOFT_ONLINE_GRAPH,
  MICROSOFT_ONLINE_MGMT,
  SET_CLOUD_SOURCE_FIELDS_DATA,
  create_data_source,
  get_client_id,
} from "../../../redux/constants/constants";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import { DataSourceErrorMessages } from "./dataSourceConstants";

const OPERATIONS = {
  GRAPH: "GRAPH",
  MANAGEMENT: "MANAGEMENT",
  DropboxConnect: "DropboxConnect",
  GoogleDriveConnect: "GoogleDriveConnect",
};

export const cloudDataSourceSave = async (
  event,
  type,
  dispatch,
  setErrors,
  setLoader,
  setSnackBarMessage,
  setOpen,
  handleValidation,
  isEditMode,
  dataSourceData,
  request,
  engine,
  currentRoute,
  cloudSourceData,
  open,
  setCloudDSSaved,
  sysGuardDataSourceData
) => {

  let url = "";
  const errors = handleValidation();
  if (Object.keys(errors).length > 0) {
    setErrors({ ...errors });
    dispatch({
      type: SET_CLOUD_SOURCE_FIELDS_DATA,
      payload: { ...cloudSourceData, noOfUsersError: errors.oneDriveUserCount },
    });
  } else {
    setLoader(true);
    setErrors({});
    dispatch({
      type: SET_CLOUD_SOURCE_FIELDS_DATA,
      payload: { ...cloudSourceData, noOfUsersError: "" },
    });
    let response = null;
    if (!isEditMode) {
      try {
        response = await getService({
          method: "POST",
          url: create_data_source(),
          headers: getAxiosHeaders(true),
          data: request,
        });
        if (response && response.status === 200) {
          let successMessage =
            "Data source saved. Redirecting for Authorization.";
          setCloudDSSaved(true);
          setSnackBarMessage(successMessage);
          setOpen(true);
        } else {
          setCloudDSSaved(false);
        }
      } catch (e) {
        //TODO handle exception show duplicate message in UI
      }
    } else {
      response = { data: engine === ENTRA ? sysGuardDataSourceData : dataSourceData };
    }

    //To get the client_id
    const clientId = await getService({
      method: "GET",
      url: get_client_id(engine),
      headers: getAxiosHeaders(true),
      timeout: 1800000,
    });

    if (
      ((!isEditMode && response && response.status === 200) || isEditMode) &&
      clientId &&
      clientId.status === 200 && !open
    ) {
      if (type === OPERATIONS.GRAPH) {
        url = MICROSOFT_ONLINE_GRAPH(
          response.data.domainId,
          clientId.data,
          response.data.id + "_" + OPERATIONS.GRAPH
        );
      } else if (type === OPERATIONS.MANAGEMENT) {
        url = MICROSOFT_ONLINE_MGMT(
          response.data.domainId,
          clientId.data,
          response.data.id + "_" + OPERATIONS.MANAGEMENT
        );
      } else if (type === OPERATIONS.DropboxConnect) {
        url = DROPBOX_CONNECT(clientId.data, response.data.id);
      } else if (type === OPERATIONS.GoogleDriveConnect) {
        url = GOOGLEDRIVE_CONNECT(clientId.data, response.data.id);
      }
      const currentOrigin = window.location.origin;
      const callbackState = currentOrigin + currentRoute.pathname;
      window.location = url + callbackState;
    } else {
      let errorMessage = "An unexpected error occurred. Please try again later.";
      setCloudDSSaved(false)
      if (response && response.response.data) {
        errorMessage = response.response.data.errorMessage;
      }
      if (url && url.error) {
        errorMessage = url.error;
      }
      setLoader(false);
      setCloudDSSaved(false)
      setSnackBarMessage(DataSourceErrorMessages[errorMessage] ? DataSourceErrorMessages[errorMessage] : errorMessage);
      setOpen(true);
    }
  }
};
