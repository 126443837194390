import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Filler,
} from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Filler);

// Function to process data
const processData = (data) => {
  const dateMap = {};

  data.forEach(({ date, userId }) => {
    if (!dateMap[date]) {
      dateMap[date] = new Set();
    }
    dateMap[date].add(userId);
  });

  return Object.keys(dateMap).map((date) => ({
    date,
    uniqueUsers: dateMap[date].size,
  }));
};

const options = {
  plugins: {
    datalabels: { display: false },
  },
  elements: {
    line: { tension: 0.4 },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxRotation: 45,
        minRotation: 45,
      },
      grid: { display: true, color: "rgba(200, 200, 200, 0.2)" },
    },
    y: {
      beginAtZero: true,
      ticks: { stepSize: 3 },
      grid: { display: true, color: "rgba(200, 200, 200, 0.2)" },
    },
  },
};

function SysGuardLineGraph({ popupData }) {
  const graphData = processData(popupData);

  const data = {
    labels: graphData.map((item) => item.date),
    datasets: [
      {
        label: "Users",
        data: graphData.map((item) => item.uniqueUsers),
        borderColor: "rgba(0, 128, 255, 1)",
        backgroundColor: "rgba(0, 128, 255, 0.3)",
        fill: true,
        pointBorderColor: "#AAA",
        pointBackgroundColor: "#FEF1F1"
      },
    ],
  };

  return (
    <div className="sys-guard-line-chart-container">
      <Line data={data} options={options} />
    </div>
  );
}

export default SysGuardLineGraph;
