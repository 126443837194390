import React, { useEffect, useState } from "react";
import {
  CREATE_RP_TEXT,
  NO_DATA_TO_SHOW_RP,
} from "../../../components/views/RiskPilot/riskPilotConstant";
import noDataAvailableSysguard from "../../../assets/icons/noDataAvailableSysguard.svg";
import { Button } from "@material-ui/core";
import OnBoardForm from "./OnBoardForm";
import { useSelector } from "react-redux";
import { isNull } from "lodash";
import SysGuardDashboard from "../Dashboard/SysGuardDashboard";
import SysGuardHeader from "../SysGuardHeader";

function NoDataAvailablePage({
  source,
  isEditMode,
  setIsEditMode,
  openOnBoardingForm,
  setOpenOnBoardingForm,
}) {
  const dataSourceData = useSelector(
    (state) => state.SysGuardReducer.sysGuardDataSource
  );
  return (
    <div>
      {dataSourceData?.connectionStatus === "Connected" ? (
        <div>
          <div className="sys-guard-home-page-container">
            <SysGuardDashboard />
          </div>
        </div>
      ) : (
        <div className="filter-table-container riskPilot">
          <div className="RRContainer">
            <img src={noDataAvailableSysguard} alt="no register icon" />
            <div className="noDataFlexContainer">
              <p className="createRRText">{NO_DATA_TO_SHOW_RP}</p>
              <div className="noDataFlexContainer">
                <p>{CREATE_RP_TEXT}</p>
                <Button
                  className="createRRButton"
                  onClick={() => setOpenOnBoardingForm(true)}
                >
                  Create
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <OnBoardForm
        openForm={openOnBoardingForm}
        setOpenForm={setOpenOnBoardingForm}
        source={source}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
    </div>
  );
}

export default NoDataAvailablePage;
