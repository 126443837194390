import React from "react";
import { HeatMapGrid } from "react-grid-heatmap";
import "../../SysGuard.scss";
import { useSelector } from "react-redux";
import { maskUsername } from "../../SysGuardCommonComp";

const UsersHeatMap = () => {
    const sysGuardHeaderData = useSelector((state) => state.SysGuardReducer.sysGuardHeaderData);
    const isIncognito = useSelector((state) => state.SysGuardReducer.incognito);
    const userChart = sysGuardHeaderData?.userChart || [];
    const heatmapData = userChart.heatmapData || [];
    const heatmapDetails = userChart.heatmapDetails || [];

    return (
        <div className="heatMapContainer">
            <HeatMapGrid
                data={heatmapData}
                cellRender={(x, y, value) => {
                    const user = heatmapDetails[x]?.[y] || {};
                    const displayName = isIncognito ? maskUsername(user.name) : user.name;
                    return (
                        <div className="heatmap-tooltip" title={`${displayName} has ${user.alertCount} alerts`}>
                            {user.abbr}
                        </div>
                    );
                }}
                cellStyle={(x, y, ratio) => ({
                    background: heatmapDetails[x]?.[y]?.bgColor || "#000",
                    fontSize: ".7rem",
                    color: `#FFFFFF`
                })}
                cellHeight="1.5rem"
                xLabelsPos="bottom"
            />
        </div>
    );
};

export default UsersHeatMap;
