import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SET_SYS_FROM_TIME_STAMP, SET_SYS_TO_TIME_STAMP } from "../../sysGuardConstants";
import { subDays, startOfDay, endOfDay } from "date-fns"; // Importing date-fns

const TimeSelector = () => {
  const [selected, setSelected] = useState("Monthly");
  const dispatch = useDispatch();

  const getEpochTime = (type) => {
    let fromDate;
    const toDate = endOfDay(new Date());

    switch (type) {
      case "Daily":
        fromDate = startOfDay(new Date());
        break;
      case "Weekly":
        fromDate = startOfDay(subDays(new Date(), 7));
        break;
      case "Monthly":
        fromDate = startOfDay(subDays(new Date(), 30));
        break;
      default:
        fromDate = startOfDay(new Date());
    }

    dispatch({ type: SET_SYS_FROM_TIME_STAMP, payload: fromDate.getTime() });
    dispatch({ type: SET_SYS_TO_TIME_STAMP, payload: toDate.getTime() });
  };

  // Dispatch "Daily" time range on first render
  useEffect(() => {
    getEpochTime("Monthly");
  }, []);

  return (
    <div>
      <div className="timeSelectorContainer">
        {["Daily", "Weekly", "Monthly"].map((option) => (
          <label key={option} style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer" }}>
            <input
              type="radio"
              name="timeSelection"
              value={option}
              checked={selected === option}
              onChange={() => {
                setSelected(option);
                getEpochTime(option);
              }}
            />
            <span style={{ color: selected === option ? "purple" : "black", fontWeight: selected === option ? "bold" : "normal" }}>
              {option}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default TimeSelector;