import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_INCOGNITO } from "./sysGuardConstants";
import { Switch, FormControlLabel } from "@mui/material";

function SysGuardIncognito({loc}) {
  const isIncognito = useSelector((state) => state.SysGuardReducer.incognito);
  const dispatch = useDispatch();

  // Toggle Incognito Mode
  const handleToggleIncognito = () => {
    const newValue = !isIncognito;
    dispatch({ type: SET_INCOGNITO, payload: newValue });
  };

  return (
    <div className={loc==="overView" ? "sys-guard-incognito-container overViewScreen" : "sys-guard-incognito-container"}>
      <FormControlLabel
        control={
          <Switch
            checked={isIncognito}
            onChange={handleToggleIncognito}
            color="primary"
          />
        }
      />
      <div>
        <img
          title={`Incognito Mode: ${isIncognito ? "Active" : "Inactive"}`}
          src={
            isIncognito
              ? "/sysGuardIcons/incognitoActive.svg"
              : "/sysGuardIcons/incognito.svg"
          }
        />
      </div>
    </div>
  );
}

export default SysGuardIncognito;
