// columnsConfig.js
import React from "react";
import { isEmpty, isNull } from "lodash";
import "../../SysGuard.scss";
import { MFALookUp, threatLevelLookup } from "../../sysGuardConstants";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import SensitivityBadge from "../../../../components/shared/Badge/SensitivityBadge";


// Register English locale (needed for name recognition)
countries.registerLocale(enLocale);

export const getCountryCode = (countryName) => {
  return countries.getAlpha2Code(countryName.trim(), "en") || countryName.trim();
};


export const renderLocationClassName = (value) => {
  if ( value === 0) {
    return "sys-guard-location green";
  } else if ( value <= 10) {
    return "sys-guard-location normal";
  } else {
    return "sys-guard-location high";
  }
};

const renderNonAnomaliesClasses = () => {
  return "sys-guard-location nonAnomalies"
}

export const renderRiskClassName = (value) => {
  const riskClasses = {
    high: "sys-guard-location highRisk",
    medium: "sys-guard-location mediumRisk",
    none: "sys-guard-location none",
  };

  return riskClasses[value?.toLowerCase()] || "sys-guard-location lowRisk";
};

export const renderNull = () => <>-</>;

const renderCellWithClass = (params, classNameFn, onCellClick, loc) => {
  if (isNull(params.value)) {
    return renderNull();
  }

  return (
    <div
      className={ !onCellClick ? classNameFn(params.value) : `${classNameFn(params.value)} pointer`}
      {...(onCellClick && {
        onClick: () => onCellClick(params.row, loc),
      })}
    >
      {threatLevelLookup[params.value] || params.value}
    </div>
  );
};

const renderApplicationName = (params) => {
  if (isNull(params.value) || isEmpty(params.value)) {
    return <div>Unknown</div>;
  } else {
    return <div>{params.value}</div>;
  }
};


const renderMFA = (params) => {
  if (isNull(params.value)) {
    return renderNull();
  } else {
    return (
      <div className={params.value === false ? "redText" : "greenText"}>
        {MFALookUp[params.value]}
      </div>
    );
  }
};

export const getFlagUrl = (code) => `https://flagcdn.com/w40/${code.toLowerCase()}.png`;

export const renderCountryNames = (params) => {
  if (!params.value) {
    return renderNull();
  }

  const countryNames = params.value.split(",");
  const countryData = countryNames.map((name) => {
    const code = getCountryCode(name);
    const flagUrl = code ? getFlagUrl(code) : null;
    return { name, code, flagUrl };
  });

  return (
    <div className="country-names-container">
      {countryData.map((country, index) => (
        <div key={index} className="country-badge" title={country.name}>
          {country.flagUrl ? (
            <img src={country.flagUrl} alt={country.name} className="country-flag" />
          ) : (
            "🏳"
          )}
          <div className="country-code">{country.code || "N/A"}</div>
        </div>
      ))}
    </div>
  );
};


const renderUserRisk = (params) => {
  if (isNull(params.value) || isEmpty(params.value)) {
    return renderNull();
  }

  const riskData = {
    sensitivity_count_high : params.value.HIGH,
    sensitivity_count_low : params.value.MEDIUM,
    sensitivity_count_medium : params.value.LOW,
    sensitivity_count_not_processed : params.value.NONE,
    total_sensitivity_count : params.row.numberOfUsers,
  }

  return (
    <div className="user-risk-container">
      <SensitivityBadge parentprops={riskData} loc="sysGuard" />
    </div>
  );
};


const columnsConfig = (onCellClick) => ({
  Locations: [
    { field: "countryName", headerName: "Country Name", flex: 0.8 },
    {
      field: "numberOfUsers",
      headerName: "No of Users",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        renderCellWithClass(params, renderNonAnomaliesClasses, onCellClick, "locationBasedUsers"),
    },
    {
      field: "risk",
      headerName: "User Risk",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => renderUserRisk(params),
    },
    {
      field: "locationAnomalies",
      headerName: "Location Based Anomalies",
      flex: 1,
      renderCell: (params) =>
        renderCellWithClass(params, renderLocationClassName, onCellClick, "locationBasedAnomalies"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "applicationInUse",
      headerName: "No of Applications",
      flex: 1,
      renderCell: (params) =>
        renderCellWithClass(params, renderNonAnomaliesClasses, onCellClick, "locationBasedApplications"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "countryRisk",
      headerName: "Threat Probability",
      flex: 1,
      renderCell: (params) => renderCellWithClass(params, renderRiskClassName),
      align: "center",
      headerAlign: "center",
    },
  ],
  Users: [
    { field: "userName", headerName: "User Name", flex: 1 },
    {
      field: "noOFLocations",
      headerName: "Locations",
      flex: 1,
      renderCell: (params) =>
        renderCellWithClass(params, renderNonAnomaliesClasses, onCellClick, "userLocations"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "noOfDeviceAnomalies",
      headerName: "Device Anomalies",
      flex: 1,
      renderCell: (params) =>
        renderCellWithClass(params, renderLocationClassName, onCellClick, "userDevices"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mfa",
      headerName: "MFA",
      flex: 1,
      renderCell: (params) => renderMFA(params),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "noOfAnomalies",
      headerName: "Behavioral Anomalies",
      flex: 1,
      renderCell: (params) =>
        renderCellWithClass(params, renderLocationClassName, onCellClick, "userAnomalies"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "noOfAppsUsed",
      headerName: "No of Applications",
      flex: 1,
      renderCell: (params) =>
        renderCellWithClass(params, renderNonAnomaliesClasses, onCellClick, "userApplications"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "risk",
      headerName: "Risk Factor",
      flex: 1,
      // Since "risk" can be an object in other tables but a string in "Users",
      // we check if it's an object before rendering to prevent React errors.
      renderCell: (params) =>
        typeof params.value === "string"
          ? renderCellWithClass(params, renderRiskClassName)
          : renderNull(), // Avoid rendering an object as a React child
      align: "center",
      headerAlign: "center",
    },
  ],
  Applications: [
    { field: "applicationName", headerName: "Application Name", flex: 1, renderCell: (params) => renderApplicationName(params) },
    {
      field: "numberOfUsers",
      headerName: "No of Users",
      flex: 1,
      renderCell: (params) =>
        renderCellWithClass(params, renderNonAnomaliesClasses, onCellClick, "applicationBasedUsers"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "risk",
      headerName: "User Risk",
      flex: 1,
      renderCell: (params) => renderUserRisk(params),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "applicationAnomalies",
      headerName: "Application Anomalies",
      flex: 1,
      renderCell: (params) =>
        renderCellWithClass(params, renderLocationClassName, onCellClick, "applicationBasedAnomalies"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "countryNames",
      headerName: "Accessed from location",
      flex: 1,
      renderCell: (params) => renderCountryNames(params),
    },
  ],
});

export default columnsConfig;
