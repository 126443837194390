import Paper from '@material-ui/core/Paper';
import CircularProgressBar from './CircularProgressBar';
import Box from '@material-ui/core/Box';
import './Badge.scss'
import { percentageCalculation } from '../../common/utilities';
import { generateSensitiveData } from '../../views/manageDataMap/locationCommonComponents';

export default function SensitivityBadge({ parentprops, loc, title }) {
  if (!parentprops) {
    return null;
  }
  const {
    total_sensitivity_count = 0
  } = parentprops;

  const sensitiveBadgeData = [];
  const sensitiveData = generateSensitiveData(loc, parentprops);

  // Check if the input data is not empty
  if (total_sensitivity_count === 0) {
    sensitiveData.forEach((e) => {
      const pDataItem = {
        name: e.name,
        color: e.color,
        percentage: 0,
        count: e.count,
        titlePercentage: "0.00"
      };
      sensitiveBadgeData.push(pDataItem);
    });
  } else {
    const floorValues = percentageCalculation(sensitiveData, total_sensitivity_count)

    //To follow color order 
    sensitiveData.forEach((item) => {
      const floorValue = floorValues.find((val) => val.color === item.color);
      const pDataItem = {
        name: item.text,
        color: item.color,
        percentage: floorValue.count,
        count: item.count || 0,
        titlePercentage: floorValue.unRoundPercentage
      };
      sensitiveBadgeData.push(pDataItem);
    });
  }

  // Function to determine the CSS classes based on the location (loc) prop
  const renderClasses = () => {
    if(loc === "InspectContent") {
      return "chip-container sensitivity inspect-content inspect-sensitivity";
    } else if (loc === "DataOwner") {
      return "chip-container sensitivity inspect-content inspect-sensitivity dataOwner"
    } 
    else if (loc === "DataOwnerTable") {
      return "chip-container sensitivity inspect-content inspect-sensitivity dataOwnerTable";
    } else {
      return "chip-container sensitivity";
    }
  }

  // Function to determine the height based on the location (loc) prop
  const renderHeight = () => {
    if(loc === "InspectContent" || loc === "DataOwner" || loc === "DataOwnerTable") {
      return 40;
    } else {
      return 45;
    }
  };

  // Function to determine the width based on the location (loc) prop
  const renderWidth = () => {
    if(loc === "InspectContent" || loc === "DataOwner" || loc === "DataOwnerTable") {
      return 40;
    }else {
      return 85;
    }
  }

  // Function to render the title based on the location (loc) prop
  const renderTitle = () => {
    if (loc === "DataOwner") {
      return (
        <div className='dataOwnerSensitiveHeading'>
          <div className='dataOwnerTitles'><span>{title}</span></div>
          <div className='dataOwnerTitles'><span>Sensitivity in no. </span></div>
        </div>
      )
    } else {
      return (
        <div className='dataOwnerTitles'>{title}</div>
      );
    }
  }

  // Function to render the file count below the badge based on the location (loc) prop
  const renderNoOfFiles = (row) => {
    if (loc === "DataOwner" || loc === "DataOwnerTable") {
      return (
        <div className={loc === "DataOwner"? `dataOwnerColor ${row.color}`: `dataOwnerColor ${row.color} table` } title={row.count}>{row.count}</div>
      );
    } else {
      return null;
    }
  }


  return (
    <Paper component="ul" className={renderClasses()}>
      {renderTitle()}
      {sensitiveBadgeData.map((row) => {
        return (
          <div className='dataOwnerSensitive'>
            <Box className="box-wrapper" sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgressBar
                strokeWidth={13}
                titlePercentage={row.titlePercentage}
                percentage={row.percentage}
                count={row.count}
                severity={row.color}
                width={renderWidth()}
                height={renderHeight()}
                title={row.name}
                loc={loc}
              />
            </Box>
            {renderNoOfFiles(row)}
          </div>
        )
      })}
    </Paper>
  )
}