import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import { DataGrid } from "@mui/x-data-grid";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { isEmpty, isNull } from "lodash";
import SysGuardLocationMap from "../Dashboard/Components/SysGuardLocationMap";
import {
  getCountryCode,
  getFlagUrl,
  renderLocationClassName,
} from "../Dashboard/ContentPages/SysGuardColumnConfig";
import CustomBarChart from "../Dashboard/Components/CustomBarChart";
import { DeviceChart } from "../Dashboard/Components/DeviceChart";
import {
  applicationPopupMainTitleLocations,
  countryWithFlagTableLocations,
  customBarChatLocations,
  maskedUsernameLoc,
  SET_SYS_GUARD_ALERT_INFO_DATA,
  sysGuardAlertInfoDataColumns,
  sysGuardPopupTitles,
  sysGuardSubHeadings,
  sysGuardTitleNumbers,
  titleWithCounts,
  unknownUser,
} from "../sysGuardConstants";
import SysGuardCountryMap from "../Dashboard/Components/SysGuardCountryMap";
import SysGuardLineGraph from "../Dashboard/Components/SysGuardLineGraph";
import SysGuardInfoBox from "../Dashboard/Components/SysGuardInfoBox";
import { useDispatch, useSelector } from "react-redux";
import { maskUsername } from "../SysGuardCommonComp";

const renderPopupTitle = (location) =>
  sysGuardPopupTitles[location] || "Behavioral Anomalies";

const renderTitleNumber = (loc, popupData) =>
  popupData?.[sysGuardTitleNumbers[loc]] ?? popupData?.noOfAppsUsed;

const renderTitleNumberClasses = (loc, data) => {
  if (titleWithCounts.includes(loc)) {
    return "sys-guard-location nonAnomalies";
  } else {
    return renderLocationClassName(data);
  }
};

const renderSubHeading = (loc) => sysGuardSubHeadings[loc] || "Anomalies:";

const renderCountryNameWithFlag = (country) => {
  const code = getCountryCode(country);
  const flagUrl = code ? getFlagUrl(code) : null;
  return (
    <>
      {flagUrl ? (
        <div className="country-with-flag">
          <img src={flagUrl} alt={country} className="country-flag Title" />
          <span className="second-title">{country}</span>
        </div>
      ) : (
        <span className="second-title">{country}</span>
      )}
    </>
  );
};

const renderMainPopupTitle = (loc, popupData, isIncognito) => {
  if (applicationPopupMainTitleLocations.includes(loc)) {
    return popupData?.applicationName || "Unknown";
  } else {
    return isIncognito ? maskUsername(popupData?.userName) : (popupData?.userName || "Unknown User");
  }
};

const UserLocationHeader = ({ popupData, loc, isIncognito }) => (
  <div className="user-location-left-panel">
    <div className="header-title">{renderPopupTitle(loc)}</div>
    <div className="second-title-container">
      {countryWithFlagTableLocations.includes(loc) ? (
        renderCountryNameWithFlag(popupData?.countryName)
      ) : (
        <span className="second-title">
          {renderMainPopupTitle(loc, popupData, isIncognito)}
        </span>
      )}
      <span className="header-title"> {`( ${renderSubHeading(loc)}`} </span>
      <span
        className={renderTitleNumberClasses(
          loc,
          renderTitleNumber(loc, popupData)
        )}
      >
        {renderTitleNumber(loc, popupData)}
      </span>
      <span className="header-title">)</span>
    </div>
  </div>
);

const handleRowClick = (data, dispatch, loc) => {
  if (sysGuardAlertInfoDataColumns.includes(loc)) {
    dispatch({ type: SET_SYS_GUARD_ALERT_INFO_DATA, payload: data });
  }
};

const UserLocationTable = ({
  sysGuardPopupData,
  totalElements,
  pagination,
  setPagination,
  tableColumns,
  dispatch,
  loc,
}) => (
  <div
    className={
      loc === "locationBasedAnomalies" || loc === "locationBasedApplications"
        ? "sys-guard-table-pop-up locationAnomaly"
        : "sys-guard-table-pop-up"
    }
  >
    <DataGrid
      rows={
        !isNull(sysGuardPopupData)
          ? sysGuardPopupData.map((row, index) => ({ ...row, id: index }))
          : []
      }
      columns={tableColumns()}
      rowCount={totalElements}
      disableSelectionOnClick
      pagination
      paginationMode="server"
      paginationModel={pagination}
      onPaginationModelChange={setPagination}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableRowSelectionOnClick={!sysGuardAlertInfoDataColumns.includes(loc)}
      localeText={{ noRowsLabel: "No Data Available" }}
      sx={{
        maxHeight: 631,
        maxWidth: 700,
        overflowY: "auto",
        "& .MuiDataGrid-virtualScroller": {
          overflowY: "auto",
        },
      }}
      getRowClassName={(params) =>
        loc === "userDevices" && params.row.alertInfo ? "highlight-row" : ""
      }
      onRowClick={(params) => handleRowClick(params.row, dispatch, loc)}
    />
  </div>
);

const renderSidePopupData = (data, loc, isIncognito) => {
  if (!data || data.length === 0) return null;

  // Sort by count (descending) and pick top 4
  const sortedData = [...data].sort((a, b) => b.count - a.count).slice(0, 4);

  // Calculate total count
  const totalCount = sortedData.reduce((sum, item) => sum + item.count, 0);

  return (
    <div className="side-popup-container">
      {sortedData.map((item, index) => {
        const percentage = (item.count / totalCount) * 100;

        return (
          <div key={index} className="side-popup-item">
            <span
              className="side-popup-name"
              title={
                isIncognito && maskedUsernameLoc.includes(loc)
                  ? maskUsername(item.name)
                  : unknownUser(item.name)
              }
            >
              {isIncognito && maskedUsernameLoc.includes(loc)
                ? maskUsername(item.name)
                : unknownUser(item.name)}
            </span>
            <div className="progress-bar">
              <div
                className="progress-fill"
                style={{ width: `${percentage}%` }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const renderSidePopupDataTitle = (location) => {
  if (location === "userLocations" || location === "locationBasedUsers") {
    return "Locations";
  } else if (location === "userDevices") {
    return "No of Devices";
  } else if (location === "userApplications" || location === "locationBasedApplications") {
    return "Applications";
  } else if (
    location === "applicationBasedUsers" ||
    location === "locationBasedAnomalies" ||
    location === "applicationBasedAnomalies"
  ) {
    return "Users";
  } else {
    return "Policies";
  }
};

const renderSideCharts = (
  location,
  sidePopupData,
  popupData,
  country,
  alertInfoData,
  isIncognito
) => {
  if (location === "userDevices" && sidePopupData) {
    return DeviceChart(sidePopupData);
  } else if (location === "userLocations") {
    return (
      <div className="sys-guard-location-side-chart">
        {renderSidePopupData(sidePopupData, location, isIncognito)}
        <SysGuardLocationMap loc="userLocations" />
      </div>
    );
  } else if (customBarChatLocations.includes(location) && sidePopupData) {
    return (
      <div className="sys-guard-location-side-chart application">
        {renderSidePopupData(sidePopupData, location, isIncognito)}
        <CustomBarChart applications={sidePopupData} loc={location} />
      </div>
    );
  } else if (location === "locationBasedUsers" && popupData && country) {
    return (
      <div className="sys-guard-location-side-chart">
        <SysGuardCountryMap
          mapData={popupData}
          countryCode={getCountryCode(country)}
        />
      </div>
    );
  } else if (
    location === "applicationBasedUsers" &&
    popupData &&
    sidePopupData
  ) {
    return (
      <div className="sys-guard-location-side-chart application">
        {renderSidePopupData(sidePopupData, location, isIncognito)}
        <SysGuardLineGraph popupData={popupData} />
      </div>
    );
  } else {
    return (
      <div
        className={
          location !== "locationBasedAnomalies"
            ? "sys-guard-location-side-chart application"
            : "sys-guard-location-side-chart location"
        }
      >
        {renderSidePopupData(sidePopupData, location, isIncognito)}
        <SysGuardInfoBox data={alertInfoData} loc={location} />
      </div>
    );
  }
};

function UserLocationPopup({
  popupOpen,
  onClose,
  popupData,
  sysGuardPopupData,
  totalElements,
  pagination,
  setPagination,
  loader,
  tableColumns,
  sidePopupData,
  loc,
}) {
  const dispatch = useDispatch();
  const alertInfoData = useSelector(
    (state) => state.SysGuardReducer.alertInfoData
  );
  const isIncognito = useSelector((state) => state.SysGuardReducer.incognito);
  return (
    <div className={popupOpen ? "sys-guard-backdrop" : ""}>
      <Popup
        open={popupOpen}
        onClose={onClose}
        modal
        closeOnDocumentClick={false}
      >
        {loader && (
          <img src={Page_Loader} alt="loading" className="sysGuardLoader" />
        )}
        <div className="sysguard-popup-container">
          <div className="user-location-container">
            <UserLocationHeader popupData={popupData} loc={loc} isIncognito={isIncognito}/>
            {isEmpty(sysGuardPopupData) || isNull(sysGuardPopupData) ? (
              <div>No Data Available</div>
            ) : (
              <>
                <div
                  className={
                    loc === "applicationBasedUsers"
                      ? "second-container application"
                      : "second-container"
                  }
                >
                  <UserLocationTable
                    sysGuardPopupData={sysGuardPopupData}
                    totalElements={totalElements}
                    pagination={pagination}
                    setPagination={setPagination}
                    tableColumns={tableColumns}
                    dispatch={dispatch}
                    loc={loc}
                  />
                  <div className="right-side-panel">
                    <div className="secondary-title">
                      {renderSidePopupDataTitle(loc)}
                    </div>
                    <div>
                      {renderSideCharts(
                        loc,
                        sidePopupData,
                        sysGuardPopupData,
                        popupData?.countryName,
                        alertInfoData,
                        isIncognito
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <button className="sys-guard-popup-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
      </Popup>
    </div>
  );
}

export default UserLocationPopup;
