import React, { useState } from "react";
import SidebarMenu from "./SideBarMenu";
import RadialProgress from "./RadialProgress";
import { useSelector } from "react-redux";

function ContentPage({selected, setSelected}) {
  const sysGuardHeaderData = useSelector((state) => state.SysGuardReducer.sysGuardHeaderData );
  return (
    <div className="sys-guard-content-page-container">
      <SidebarMenu onSelect={setSelected} selected={selected} />
      <div className="sys-guard-content-page">
        <div className="sys-guard-content-header-container"><RadialProgress loc="Content" data={sysGuardHeaderData}/></div>
      </div>
    </div>
  );
}

export default ContentPage;