import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../SysGuard.scss";
import columnsConfig from "../ContentPages/SysGuardColumnConfig";
import { isNull } from "lodash";
import { useSelector } from "react-redux";

function DataTable({
  data,
  type,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  totalElements,
  setPopupDetails,
  setPopupOpen,
}) {
  const handleCellClick = (data, loc) => {
    setPopupDetails({
      popupData: data,
      popupLocation: loc,
    });
    setPopupOpen(true);
  }
  const columns = columnsConfig(handleCellClick)[type] || [];

  return (
    <div className="tableContainer">
      <DataGrid
        rows={
          !isNull(data) ? data.map((row, index) => ({ ...row, id: index })) : []
        }
        columns={columns}
        rowCount={totalElements}
        autoHeight
        disableSelectionOnClick
        className="dataGrid"
        pagination
        paginationMode="server"
        paginationModel={{ page: currentPage, pageSize }}
        onPaginationModelChange={(newModel) => {
          setCurrentPage(newModel.page);
          setPageSize(newModel.pageSize);
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableRowSelectionOnClick
        localeText={{
          noRowsLabel: "No Data Available",
        }}
      />
    </div>
  );
}

export default DataTable;
