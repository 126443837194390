import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import { SYS_GUARD } from "../sysGuardConstants";
import AlertDrawer from "../../../components/shared/alertDrawer";

function OnBoardForm({
  openForm,
  setOpenForm,
  source,
  isEditMode,
  setIsEditMode,
}) {
  const handleClose = (event) => {
    setOpenForm(false);
    setIsEditMode(false);
  };
  const renderOnBoardForm = () => {
    return (
      openForm && (
        <AlertDrawer
          toggleDrawer={setOpenForm}
          stateOpen={openForm}
          isEditMode={isEditMode}
          loc="create-data-source"
        />
      )
    );
  };
  const renderClassNames = () => {
    if (source === SYS_GUARD) {
      return "sysGuardDrawer";
    }
  };
  return (
    <React.Fragment>
      <Drawer
        classes={renderClassNames()}
        anchor={"right"}
        open={openForm}
        onClose={(event) => handleClose(event)}
      >
        {renderOnBoardForm()}
      </Drawer>
    </React.Fragment>
  );
}

export default OnBoardForm;
