import React from "react";
import Typography from "@material-ui/core/Typography";
import { getTimeForToolTip, sourceIcons } from "../../common/utilities";
import inspectIcon from "../../../assets/icons/inspectIcon.svg";
import AlertBadge from "../Badge/AlertBadge";
import SensitivityBadge from "../Badge/SensitivityBadge";
import CombinedShapeCross from "../../../assets/icons/CombinedShapeCross.svg";
import { frameTooltipData } from "../../common/utilities";
import { useDispatch } from "react-redux";
import { CLOSE_TOOLTIP, ONEDRIVE, DROPBOX, OTHER, GOOGLEDRIVE, NTFS, ENTRA } from "../../../redux/constants/constants";
import { renderDataMapIcons, isCloudFileSystem } from "../../common/utilities";
import { isEmpty } from "lodash";

export default function DataMapTooltip({ hoverNode, toolTipData, parentprops }) {
  const { alertToolTipData, sensitiveData } = frameTooltipData(parentprops.data);
  const sourceType = hoverNode.subType;
  const dispatch = useDispatch();
  const cloudFileSystem = isCloudFileSystem(sourceType.code) || sourceType.code === NTFS;

  const closeTooltip = () => {
    dispatch({ type: CLOSE_TOOLTIP, payload: { node: hoverNode } })
  }


  const renderIconClasses = () => {
    if (cloudFileSystem) {
      if (sourceType.code === ONEDRIVE) {
        return "tooltip-images oneDrive";
      } else if (sourceType.code === DROPBOX ) {
        return "tooltip-images Dropbox";
      }
    } else {
      return "tooltip-images";
    }
  };

  const renderImageClass = () => {
    if (sourceType.code === DROPBOX) {
      return "DropboxIcon";
    } else if (sourceType.code === GOOGLEDRIVE) {
      return "GoogleDriveIcon";
    } else if (sourceType.code === ENTRA) {
      return "EntraIcon"
    } else {
      return "oneDriveColorIcon";
    }
  };

  const renderImages = () => {
    return (
      <div className={renderIconClasses()}>
        <img className={renderImageClass()} src={sourceIcons(sourceType)} alt="oneDrive Icon" title={sourceType.code} />
        {
          (!cloudFileSystem && sourceType && !isEmpty(renderDataMapIcons(sourceType)) && sourceType.code !== OTHER ) ? (
            <img className="oneDriveColorIcon" src={renderDataMapIcons(sourceType)} alt="oneDrive Icon" title={sourceType.code} />
          ) : null
        }
      </div>
    );
  }

  const sourceHeading = () => {
    if (sourceType.type.code === "APPLICATION") {
      return (
        <div className="tooltip-header-container">
          <div className="heading apmData" title={hoverNode && hoverNode.label}>{hoverNode && hoverNode.label}
          </div>
          {renderImages()}
        </div>
      );
    } else {
      return (
        <div className="tooltip-header-container">
          <div className="heading" title={hoverNode && hoverNode.label}>{hoverNode && hoverNode.label}</div>
          {renderImages()}
        </div>
      );
    }
  };


  //NTFS will not have sensitivity badge if the does not have sensitive data
  const renderSensitivityBadge = () => {
    const {
      sensitivity_count_high,
      sensitivity_count_medium,
      sensitivity_count_low,
      sensitivity_count_not_processed,
    } = sensitiveData;

    if (
      sourceType.code === NTFS &&
      sensitivity_count_high === 0 &&
      sensitivity_count_medium === 0 &&
      sensitivity_count_low === 0 &&
      sensitivity_count_not_processed !== 0
    ) {
      return null;
    }

    return (
      <div className="border-top">
        <SensitivityBadge title={"Sensitivity:"} parentprops={sensitiveData} />
      </div>
    );
  };

  const badges = () => {
    if (sourceType.type.code === "APPLICATION") {
      return (
        <>
          <div>
            <AlertBadge title={"Alerts:"} parentprops={alertToolTipData} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <AlertBadge title={"Alerts:"} parentprops={alertToolTipData} />
          </div>
          {renderSensitivityBadge()}
        </>
      );
    }
  };
  const renderformattedNumber = (count) => {
    if (count > 0 && count < 10) {
      return "0" + count;
    } else {
      return count;
    }
  }
  return (
    <Typography>
      <div className="oneDrive-tooltip-box">
        <div className="tt-header">
          <div className="inspect-header-container">
            <div className="inspectHeader">
              {sourceHeading()}
            </div>
            <div className="inspect-tooltip-close">
              <img src={CombinedShapeCross} alt="Close Icon" title="close" onClick={closeTooltip} />
            </div>
          </div>
        </div>
        <div className="tt-body">
          {badges()}
          <div className="border-top additional-content">
            <div className="addition-child-content file-count">
              <div className="file-count-align dataOwnerTitles">{cloudFileSystem ? 'Files:' : 'Fields:'}</div>
              <div>{renderformattedNumber(sensitiveData['total_sensitivity_count'])}</div>
            </div>
            <div className="addition-child-content file-date">
              <div className="dataOwnerTitles">Added: </div>
              <div>{getTimeForToolTip(hoverNode.creationTime)}</div>
            </div>
          </div>
        </div>
        <div className="tt-footter">
          <div className="inspectFotter">
            <img className="inspectIcon" src={inspectIcon} alt="Inspect Icon" />
            <div
              onClick={() => {
                parentprops.handleSelectedNode(
                  parentprops.nodeInfo,
                  hoverNode.label,
                  parentprops.subTypeInfo,
                  hoverNode.creationTime,
                  hoverNode.subType,
                  toolTipData,
                  parentprops.data
                );
                parentprops.bottomTrayEnableEve();
              }}
              style={{ textDecoration: "underline", cursor: "pointer", color: "#081981", fontSize: "18px" }}
            >
              Inspect Content
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
}