import React, { useEffect } from 'react'
import RadialProgress from './Components/RadialProgress'
import { useDispatch, useSelector } from 'react-redux'
import { GET_SYS_GUARD_HEADER_DATA } from '../sysGuardConstants';

function SysGuardDashboardHeader() {
  const dispatch = useDispatch();
  const sysGuardHeaderData = useSelector((state) => state.SysGuardReducer.sysGuardHeaderData )
  useEffect(() => {
    dispatch({type: GET_SYS_GUARD_HEADER_DATA});
  },[])
  return (
    <div className="sys-guard-dashboard-header-container">
      <RadialProgress data={sysGuardHeaderData}/>
    </div>
  )
}

export default SysGuardDashboardHeader