export const maskUsername = (input) => {
  if (!input || typeof input !== "string") return "*"; // Return empty string if input is missing or not a string

  // Detect if input is an email address
  if (input.includes("@") && input.includes(".")) {
    const [localPart, domain] = input.split("@");
    if (!localPart || !domain) return input; // Ensure valid email structure

    const [domainName, topLevelDomain] = domain.split(".");
    if (!domainName || !topLevelDomain) return input; // Handle invalid domain

    const maskedLocal = localPart[0] + "*".repeat(localPart.length - 1);
    const maskedDomain = domainName[0] + "*".repeat(domainName.length - 1);
    return `${maskedLocal}@${maskedDomain}.${topLevelDomain}`;
  }

  // Detect if input contains a dot but is not an email (e.g., "John.Doe")
  if (input.includes(".") && !input.includes("@")) {
    return input
      .split(".")
      .map((part) => (part.length > 0 ? part[0] + "*".repeat(part.length - 1) : ""))
      .join(".");
  }

  // Handle names with spaces (assumed first and last name)
  const nameParts = input.split(" ");
  if (nameParts.length > 1) {
    const mask = (name) =>
      name.length > 3
        ? name.slice(0, 3) + "*".repeat(name.length - 3)
        : name[0] + "*".repeat(name.length - 1);
    return `${mask(nameParts[0])} ${mask(nameParts[nameParts.length - 1])}`;
  }

  // Single-word names
  return input.length > 3
    ? input.slice(0, 3) + "*".repeat(input.length - 3)
    : input[0] + "*".repeat(Math.max(input.length - 1, 0)); // Ensure safe masking
};
