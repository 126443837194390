import {
  CLEAR_SYS_GUARD_ALERT_INFO_DATA,
  SET_SYS_FROM_TIME_STAMP,
  SET_SYS_GUARD_CITY_DATA,
  SET_SYS_GUARD_DATASOURCE,
  SET_SYS_GUARD_DISTINCT_LOCATIONS,
  SET_SYS_GUARD_HEADER_DATA,
  SET_SYS_GUARD_PAGE_LOADER,
  SET_SYS_GUARD_POPUP_DATA,
  SET_SYS_GUARD_POPUP_TOTAL_ELEMENTS,
  SET_SYS_GUARD_SIDE_POPUP_DATA,
  SET_SYS_GUARD_TABLE_DATA,
  SET_SYS_TO_TIME_STAMP,
  SET_TOTAL_ELEMENTS,
  SET_SYS_GUARD_ALERT_INFO_DATA,
  SET_INCOGNITO,
  CLEAR_SYS_GUARD_POPUP_DATA,
  CLEAR_SYS_GUARD_TABLE_DATA,
} from "../sysGuardConstants";

const initialState = {
  sysGuardDataSource: null,
  loader: false,
  fromTimeStamp: null,
  toTimeStamp: null,
  sysGuardTableData: null,
  totalElements: 0,
  sysGuardHeaderData: null,
  sysGuardPopupData: null,
  popupTotalElements: 0,
  sidePopupData: null,
  distinctLocations: null,
  cities: [],
  mapCenter: null,
  alertInfoData: null,
  incognito: true,
};

export const SysGuardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SYS_GUARD_DATASOURCE: {
      return {
        ...state,
        sysGuardDataSource: action.payload,
      };
    }
    case SET_SYS_GUARD_PAGE_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case SET_SYS_FROM_TIME_STAMP: {
      return {
        ...state,
        fromTimeStamp: action.payload,
      };
    }
    case SET_SYS_TO_TIME_STAMP: {
      return {
        ...state,
        toTimeStamp: action.payload,
      };
    }
    case SET_SYS_GUARD_TABLE_DATA: {
      return {
        ...state,
        sysGuardTableData: action.payload,
      };
    }
    case SET_TOTAL_ELEMENTS: {
      return {
        ...state,
        totalElements: action.payload,
      };
    }
    case SET_SYS_GUARD_HEADER_DATA: {
      return {
        ...state,
        sysGuardHeaderData: action.payload,
      };
    }
    case SET_SYS_GUARD_POPUP_DATA: {
      return {
        ...state,
        sysGuardPopupData: action.payload,
      };
    }
    case SET_SYS_GUARD_POPUP_TOTAL_ELEMENTS: {
      return {
        ...state,
        popupTotalElements: action.payload,
      };
    }
    case SET_SYS_GUARD_SIDE_POPUP_DATA: {
      return {
        ...state,
        sidePopupData: action.payload,
      };
    }
    case SET_SYS_GUARD_DISTINCT_LOCATIONS: {
      return {
        ...state,
        distinctLocations: action.payload,
      };
    }
    case SET_SYS_GUARD_CITY_DATA: {
      return {
        ...state,
        cities: action.payload.cities,
        mapCenter: action.payload.mapCenter,
      };
    }
    case SET_SYS_GUARD_ALERT_INFO_DATA: {
      return {
        ...state,
        alertInfoData: action.payload
      }
    }
    case SET_INCOGNITO: {
      return {
        ...state,
        incognito: action.payload
      }
    }
    case CLEAR_SYS_GUARD_ALERT_INFO_DATA: {
      return {
        ...state,
        alertInfoData: null
      }
    }
    case CLEAR_SYS_GUARD_TABLE_DATA: {
      return {
        ...state,
        sysGuardTableData: null
      }
    }
    case CLEAR_SYS_GUARD_POPUP_DATA: {
      return {
        ...state,
        sysGuardPopupData: null
      }
    }
    default:
      return state;
  }
};
