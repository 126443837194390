import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip as MuiTooltip,
} from "@mui/material";
import { isEmpty, isNull } from "lodash";

import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";

const DEVICE_COLORS = [
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#ff7300",
  "#d0ed57",
  "#a4de6c",
  "#0088fe",
  "#00c49f",
  "#ffbb28",
  "#ff8042",
];

export const DeviceChart = (chartData) => {

  // Sort by count in descending order and take the top 10
  const data = [...chartData].sort((a, b) => b.count - a.count).slice(0, 10);

  if (!data || data.length === 0) return null;

  const totalCount = data.reduce((sum, item) => sum + item.count, 0);
  const uniqueCount = chartData.length;

  return (
    <div className="device-chart-container">
      <ResponsiveContainer width={250} height={200}>
        <PieChart>
          <Pie
            data={data}
            dataKey="count"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={100}
            paddingAngle={2}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={DEVICE_COLORS[index]} />
            ))}
            <Label
              content={({ viewBox }) => (
                <MuiTooltip title="Total Unique Devices" arrow>
                  <text
                    x={viewBox.cx}
                    y={viewBox.cy}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    {uniqueCount}
                  </text>
                </MuiTooltip>
              )}
            />
          </Pie>
          <Tooltip formatter={(value, name) => [`${value}`, `${name ? name : "Unknown"}`]} />
        </PieChart>
      </ResponsiveContainer>

      <div className="sys-guard-device-pop-table-container">
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Device Name</TableCell>
                <TableCell align="center" className="sys-guard-table-cell">
                  Activities
                </TableCell>
                <TableCell align="center">Percentage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => {
                const percentage = ((item.count / totalCount) * 100).toFixed(1);
                return (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor:
                        DEVICE_COLORS[index % DEVICE_COLORS.length],
                      color: "white",
                    }}
                  >
                    <TableCell>{isNull(item.name) || isEmpty(item.name) ? "Unknown" : item.name}</TableCell>
                    <TableCell align="center">{item.count}</TableCell>
                    <TableCell align="center">{percentage}%</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
