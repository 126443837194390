import { getTime, renderDataSource, renderSensitivityValue } from "../../common/utilities";
import LevelComponent from "../../shared/levelComponent";
import { DeleteButtonForToolBar } from "../../common/buttons";
import ViewDatabase from "../../../assets/icons/viewDatabase.svg";
import { withStyles } from "@material-ui/core/styles";
import ViewTooltip from "@material-ui/core/Tooltip";
import {DATABASE_FROM_TIME_VALUE, DATABASE_TO_TIME_VALUE, GET_SERVICE_ACCESS_LOGS, SET_LOCATION, SET_USERS} from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import { useSelector, useDispatch } from "react-redux";
import { renderDateTime } from "../Util/ViewCommon";
import { fetchAndDispatchAllData } from "../manageDataMap/mapUtilities";


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  arrow: {
    fontSize: 16,
    width: 17,
    "&::before": {
      border: "1px solid #fff",
      backgroundColor: "white",
      boxSizing: "border-box",
      boxShadow: " 0 0 2px #0004",
    },
  },
  disbaled:{
    opacity:"0.5"
  }
}))(ViewTooltip);


export const tableConstants = () => {
  return [
    {
      title: "Time",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", whiteSpace: "nowrap" },
      render: (rowData) => {
        return <span>{renderDateTime(rowData.timeStamp)}</span>;
      },
    },
    {
      title: "Username",
      style: { width: "12%" },
      rowBodyCellStyle: { width: "12%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <span>
            {rowData.enterpriseActor.username
              ? rowData.enterpriseActor.username
              : ""}
          </span>
        );
      },
    },
    {
      title: "Location",
      style: { width: "12%" },
      rowBodyCellStyle: { width: "12%", wordBreak: "break-all" },
      render: (rowData) => {
        const location = rowData.city && rowData.country
          ? rowData.city === rowData.country
            ? rowData.city
            : `${rowData.city}, ${rowData.country}`
          : rowData.city || rowData.country || "";
        return (
          <span>
            {location}
          </span>
        );
      },
    },
    {
      title: "IP Address",
      style: { width: "12%" },
      rowBodyCellStyle: { width: "12%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <span>
            {rowData.clientIp
              ? rowData.clientIp
              : ""}
          </span>
        );
      },
    },
    {
      title: "Datasource Name",

      headerClassName: "direct-data-table-head",
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        const dataSourceType = rowData.dataSource.dataSourceType.code ? rowData.dataSource.dataSourceType.code : "";
        const dataSourceName = rowData.dataSource.aliasName ? rowData.dataSource.aliasName : "";
        return (
          <span>
            {renderDataSource(dataSourceType, dataSourceName)}
          </span>
        );
      },
    },
    {
      title: "Data Source Role",

      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        let str = "",
          names = [];
        if (rowData.roles != null) {
          rowData.roles.forEach((element) => {
            names.push(element.name);
          });
          str = names.join(",");
        }

        return <span>{str ? str : ""}</span>;
      },
    },
    {
      title: "Sensitivity Level",
      style: { width: "8%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <span>
            {/* {rowData.sensitivityLevel?rowData.sensitivityLevel:""}
             */}
            <LevelComponent level={renderSensitivityValue(rowData.sensitivityLevel.toUpperCase())} />
          </span>
        );
      },
    },
    {
      title: "Access Details",

      style: { width: "35%" },
      rowBodyCellStyle: { width: "35%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.sqlStatement ? rowData.sqlStatement : ""}</span>;
      },
    },
    {
      title: "Number Of Executions",
      loadValueOnClick: true,
      style: {width: "15%", textAlign: "center"},
      rowBodyCellStyle: {width: "15%", wordBreak: "break-all", textAlign: "center"},
      render: (rowData) => {
        return <span>{rowData.noOfExecutions ? rowData.noOfExecutions: 'NA'}</span>;
      }
    }
  ];
};
export const tableConstants_ServiceAccessLogsDrawer = () => {
  return [
    {
      title: "Data Source Name",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.dataBaseName ? rowData.dataBaseName : ""}</span>;
      },
    },

    {
      title: "Data Source Username",

      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <span>{rowData.dataBaseUsername ? rowData.dataBaseUsername : ""}</span>
        );
      },
    },
    {
      title: "Data Source Role",

      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.roles ? rowData.roles : ""}</span>;
      },
    },
    {
      title: "Access Statement",

      style: { width: "30%" },
      rowBodyCellStyle: { width: "30%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <span>{rowData.sqlStatement ? rowData.sqlStatement : ""}</span>
        );
      },
    },
  ];
};
export const tableConstants_ServiceAccessLogs = () => {
  return [
    {
      title: "Time",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{renderDateTime(rowData.startTime)}</span>;
      },
    },
    {
      title: "Service User",
      style: { width: "8%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <span>
            {rowData.enterpriseActor ? rowData.enterpriseActor.username : " "}
          </span>
        );
      },
    },
    {
      title: "Application Name",
      style: { width: "8%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <span>
            {rowData.application ? rowData.application.applicationName : ""}
          </span>
        );
      },
    },
    {
      title: "Service Name",
      style: { width: "7%" },
      rowBodyCellStyle: { width: "7%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <span>{rowData.service ? rowData.service.serviceName : ""}</span>
        );
      },
    },
    
    {
      title: "Sensitivity Level",
      style: { width: "6%" },
      rowBodyCellStyle: { width: "6%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <span>
            {rowData.sensitivityLevel != null ? (
              <LevelComponent level={rowData.sensitivityLevel !== "null" ? renderSensitivityValue(rowData.sensitivityLevel.toUpperCase()):""} />
            ) : (
              ""
            )}
          </span>
        );
      },
    },
    {
      title: "Data Source Username",

      style: { width: "8%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <>
            {rowData.dataSourceUserNames
              ? rowData.dataSourceUserNames.map((element) => {
                  return (
                    <span>
                      {element} <br />
                    </span>
                  );
                })
              : ""}
          </>
        );
      },
    },
    {
      title: "Data Source Role",

      style: { width: "8%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <>
            {rowData.dataSourceList
              ? rowData.dataSourceList.map((element) => {
                  let str = "",
                    names = [];
                  if (element.roles != null) {
                    element.roles.forEach((data) => {
                      names.push(data.name);
                    });
                   
                  }
                  str = names.join(" ,");
                  return <span>{str ? str : ""}</span>;
                })
              : ""}
          </>
        );
      },
    },
    {
      title: "Data Source Name",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <>
            {rowData.dataSourceList
              ? rowData.dataSourceList.map((element) => {
                  return (
                    <span>
                      {element.aliasName} <br />
                    </span>
                  );
                })
              : ""}
          </>
        );
      },
    },

    {
      title: "Access Statement",
      style: { width: "50%" },
      rowBodyCellStyle: { width: "50%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          <>
            {rowData.sqlStatements
              ? rowData.sqlStatements.map((element) => {
                  return (
                    <span>
                      {element.length >= 80
                        ? element.substring(0, 80) + "......."
                        : element}{" "}
                      <br />
                    </span>
                  );
                })
              : ""}
          </>
        );
      },
    },
  ];
};

export const locationFilterData = (filterData, dispatch) => {
  const toTime = Date.now();
  const fromTime = new Date();
  fromTime.setDate(fromTime.getDate() - 3);

  fetchAndDispatchAllData(dispatch);
  dispatch({ type: DATABASE_FROM_TIME_VALUE, payload: fromTime.getTime() });
  dispatch({ type: DATABASE_TO_TIME_VALUE, payload: toTime });
  dispatch({
    type: "SET_DATA_SOURCE_NAME",
    payload: filterData.data?.dataSource?.id || "",
  });
  dispatch({
    type: "MAINTAIN_SINGLE_DATA",
    payload: {
      name: filterData.data?.dataSource?.name || "",
      value: filterData.data?.dataSource?.id || "",
      dispatchName: "SET_DATA_SOURCE_NAME",
    },
  });
  dispatch({ type: SET_USERS, payload: filterData.data?.username || "" });
  dispatch({
    type: "MAINTAIN_SINGLE_DATA",
    payload: {
      name: filterData.data?.username || "",
      value: filterData.data?.username || "",
      dispatchName: "SET_USERS",
    },
  });
  dispatch({ type: SET_LOCATION, payload: { city: filterData.city.trim(), country: filterData.country.trim() } });
  dispatch({
    type: "MAINTAIN_SINGLE_DATA",
    payload: {
      name: `${filterData.city.trim()}, ${filterData.country.trim()}`,
      value: { city: filterData.city.trim(), country: filterData.country.trim() },
      dispatchName: SET_LOCATION,
    },
  });
  dispatch({ type: "SET_APPLY_DATA" });
  dispatch({ type: "FULL_RESET_DB_LOGS" });
  dispatch({ type: "GET_DATABASE_LOGS_INFO_DATA" });
};
