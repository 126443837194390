import React from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { calculatePercentage } from "../../sysGuardConstants";

const RadialProgress = ({loc, data}) => {
  //check the data format if it fails to work - iyer
  const location = data?.location;
  const device = data?.device;
  const mfa = data?.mfa;
  const suspicious = data?.suspicious;
  const radialData = [
    {
      percentage: calculatePercentage(location?.alertCount, location?.totalCount),
      value: location?.alertCount,
      maxValue: location?.totalCount,
      title: "Location Anomalies",
      img: "/sysGuardIcons/locationIcon.svg",
      colorImg: "/sysGuardIcons/colorLocation.svg",
      alt: "location Icon",
      progressColor: "#a6ce39"
    },
    {
      percentage: calculatePercentage(device?.alertCount, device?.totalCount),
      value: device?.alertCount,
      maxValue: device?.totalCount,
      title: "Device Based Risk",
      img: "/sysGuardIcons/fingerPrint.svg",
      colorImg: "/sysGuardIcons/colorFingerprint.svg",
      alt: "finger Icon",
      progressColor: "#dd5434"
    },
    {
      percentage: calculatePercentage(mfa?.enabledCount, mfa?.totalCount),
      value: mfa?.enabledCount,
      maxValue: mfa?.totalCount,
      title: "Accounts W/O MFA",
      img: "/sysGuardIcons/shield.svg",
      colorImg: "/sysGuardIcons/colorShield.svg",
      alt: "shield Icon",
      progressColor: "#e9b047"
    },
    {
      percentage: calculatePercentage(suspicious?.alertCount, suspicious?.totalCount),
      value: suspicious?.alertCount,
      maxValue: suspicious?.totalCount,
      title: "Suspicious User Behavior",
      img: "/sysGuardIcons/danger.svg",
      colorImg: "/sysGuardIcons/colorDanger.svg",
      alt: "danger Icon",
      progressColor: "#e33d47"
    }
  ];

  const renderProgressColor = (value) => {
    if (value === 0) {
      return "#8dc63f";
    } else if ( value <= 50) {
      return "#e9b047";
    } else {
      return "#f43860"
    }
  }

  const renderClassNames = () => {
    if (loc === "Content") {
      return "sys-guard-radial-progress-bar-container sys-guard-content";
    } else {
      return "sys-guard-radial-progress-bar-container";
    }
  };

  return (
    <div className={renderClassNames()}>
      {radialData.map((e, index) => (
        <div key={index} className="sys-guard-text-container">
          <img src={e.img} alt={e.alt} />
          <div className="sys-guard-radial-text">{e.title}</div>
          <div className="sys-guard-radial-progress">
            <CircularProgressbarWithChildren
              value={e.percentage}
              styles={buildStyles({
                pathColor: renderProgressColor(e.percentage),
                strokeLinecap: "butt",
                trailColor: "#8dc63f",
              })}
              strokeWidth={10}
            >
              <div className="circular-progress-bar-text">
                <div className="text-sm">
                  {e.value}/{e.maxValue}
                </div>
                <div
                  className={loc === "Content" ? "content" : "text-l"}
                >{`${e.percentage}%`}</div>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RadialProgress;
