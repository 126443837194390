import React from "react";
import SysGuardDashboardHeader from "./SysGuardDashboardHeader";
import SysGuardCardOverview from "./Components/SysGuardCardOverview";

function SysGuardDashboard() {
  return (
    <div className="sys-guard-dashboard-main-container">
      <SysGuardDashboardHeader/>
      <SysGuardCardOverview/>
  </div>
  )
}

export default SysGuardDashboard;
