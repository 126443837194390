import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useSelector } from "react-redux";
import { isEmpty, isNull } from "lodash";
import { largeMapMarkers } from "../../sysGuardConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function SysGuardLocationMap({ loc }) {
  const history = useHistory();
  const sysGuardHeaderData = useSelector(
    (state) => state.SysGuardReducer.sysGuardHeaderData
  );
  const dashboardLocations = sysGuardHeaderData?.locationChart
    ? sysGuardHeaderData?.locationChart
    : [];
  const distinctLocation =
    useSelector((state) => state.SysGuardReducer.distinctLocations) || [];
  const center = [20, 0]; // Center on world

  const locations =
    loc === "userLocations" ? distinctLocation : dashboardLocations;

  const createDotIcon = (color, width, height) =>
    new L.DivIcon({
      className: "custom-icon",
      html: `<div style="
          background-color: ${color}; 
          border-radius: 50%; 
          width: ${width}px; 
          height: ${height}px;
          border: 1px solid white;
        "></div>`,
      iconSize: [6, 6],
      iconAnchor: [3, 3],
    });

  const getSizeBasedOnLoc = () => (largeMapMarkers.includes(loc) ? 12 : 8);

  // Dynamically set icon size
  const getDotIcon = (color) => {
    const size = getSizeBasedOnLoc();
    return createDotIcon(color, size, size);
  };

  const redDotIcon = () => getDotIcon("#ff2251");
  const greenDotIcon = () => getDotIcon("#2ce68f");

  const mapConfig = {
    default: {
      mapCenter: center,
      zoom: 0.67,
      className: "sys-guard-map",
      zoomControl: false,
      scrollWheelZoom: false,
      dragging: false,
      doubleClickZoom: false,
      touchZoom: false,
      worldCopyJump: false,
      minZoom: 0.67,
      maxZoom: 0.67,
    },
    dashboardPopup: {
      mapCenter: center,
      zoom: 2,
      className: "sys-guard-map dashboard",
      zoomControl: true,
      scrollWheelZoom: true,
      dragging: true,
      doubleClickZoom: false,
      touchZoom: false,
      worldCopyJump: false,
      minZoom: 2,
      maxZoom: 8,
      maxBounds: [
        [-85, -170],
        [85, 170],
      ],
    },
    userLocations: {
      mapCenter: center,
      zoom: 0.67,
      className: "sys-guard-map",
      zoomControl: false,
      scrollWheelZoom: true,
      dragging: true,
      doubleClickZoom: false,
      touchZoom: false,
      worldCopyJump: false,
      minZoom: 0.67,
      maxZoom: 8,
      maxBounds: [
        [-85, -170],
        [85, 170],
      ],
    },
  };

  const mapSettings = mapConfig[loc] ? mapConfig[loc] : mapConfig.default;

  const renderClassName = () => {
    if (loc === "userLocations") {
      return "sys-guard-map-container location";
    } else if (loc === "dashboardPopup") {
      return "sys-guard-map-container dashboard";
    } else {
      return "sys-guard-map-container";
    }
  };

  return (
    <div className={renderClassName()}>
      {mapSettings && !isNull(mapSettings) && !isEmpty(mapSettings) && (
        <MapContainer
          center={mapSettings.mapCenter}
          zoom={mapSettings.zoom}
          className={mapSettings.className}
          zoomControl={mapSettings.zoomControl}
          scrollWheelZoom={mapSettings.scrollWheelZoom}
          dragging={mapSettings.dragging}
          doubleClickZoom={mapSettings.doubleClickZoom}
          touchZoom={mapSettings.touchZoom}
          worldCopyJump={mapSettings.worldCopyJump}
          minZoom={mapSettings.minZoom}
          maxZoom={mapSettings.maxZoom}
          maxBounds={mapSettings.maxBounds}
        >
          <TileLayer
            url="https://cartodb-basemaps-a.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
            noWrap={true}
          />

          {locations.map((location, index) => (
            <Marker
              key={index}
              position={location.position}
              icon={
                location.category === "alert" ? redDotIcon() : greenDotIcon()
              }
              eventHandlers={
                loc === "dashboardPopup"
                  ? {
                      click: () =>
                        history.push(`/mdr/sysguard/content/Locations`),
                    }
                  : {}
              }
            />
          ))}
        </MapContainer>
      )}
    </div>
  );
}

export default SysGuardLocationMap;
