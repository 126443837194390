import { isEmpty, isNull } from "lodash";
import {
  renderNull,
  renderRiskClassName,
} from "../Dashboard/ContentPages/SysGuardColumnConfig";
import { useSelector } from "react-redux";
import { maskUsername } from "../SysGuardCommonComp";

const renderLocationWithRisk = (params) => {
  if (isNull(params.value)) {
    return renderNull();
  } else {
    return (
      <div className={renderRiskClassName(params.row.countryRisk)}>
        {params.value}
      </div>
    );
  }
};

const RenderUserName = (params) => {
  const isIncognito = useSelector((state) => state.SysGuardReducer.incognito);
  if (isNull(params.value) || isEmpty(params.value)) {
    return <div>Unknown</div>;
  } else if (isIncognito) {
    return <div>{maskUsername(params.value)}</div>;
  } else {
    return <div>{params.value}</div>;
  }
};

const renderClientIp = (params) => {
  if (isNull(params.value)) {
    return renderNull();
  } else {
    return <div>{params.value}</div>
  }
}

const renderName = (params) => {
  if (isNull(params.value) || isEmpty(params.value)) {
    return <div>Unknown</div>
  } else {
    return <div>{params.value}</div>
  }
}

export const userLocationColumns = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "time",
    headerName: "Time",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "location",
    headerName: "City",
    flex: 2,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => renderLocationWithRisk(params),
  },
  {
    field: "clientIp",
    headerName: "IP Address",
    flex: 2,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => renderClientIp(params),
  }
];

export const userDeviceColumns = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "time",
    headerName: "Time",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "deviceName",
    headerName: "Device Name",
    flex: 1.5,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => renderName(params),
  },
  {
    field: "deviceOs",
    headerName: "Device OS",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
];

export const userAnomaliesColumn = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "time",
    headerName: "Time",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "policyName",
    headerName: "Policy",
    flex: 2.5,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => renderName(params),
  },
];

export const userApplicationsColumn = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "time",
    headerName: "Time",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "appName",
    headerName: "Application",
    flex: 2.5,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => renderName(params),
  },
  {
    field: "deviceName",
    headerName: "Device Name",
    flex: 1.5,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => renderName(params),
  },

];

export const locationBasedUsersColumn = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "time",
    headerName: "Time",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "userName",
    headerName: "User Name",
    flex: 2,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => RenderUserName(params),
  },
  {
    field: "clientIp",
    headerName: "IP Address",
    flex: 1.5,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => renderClientIp(params),
  }
];

export const locationBasedAnomaliesColumn = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "time",
    headerName: "Time",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "userName",
    headerName: "User Name",
    flex: 1.5,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => RenderUserName(params),
  },
  {
    field: "clientIp",
    headerName: "IP Address",
    flex: 2,
    align: "left",
    headerAlign: "left",
  }
];

export const locationBasedApplicationColumn = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "time",
    headerName: "Time",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "appName",
    headerName: "Application",
    flex: 2,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => renderName(params),
  },
  {
    field: "userName",
    headerName: "User Name",
    flex: 1,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => RenderUserName(params),
  },
];

export const applicationBasedUsersColumn = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "time",
    headerName: "Time",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "userName",
    headerName: "User Name",
    flex: 2,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => RenderUserName(params),
  },
  {
    field: "deviceName",
    headerName: "Device Name",
    flex: 1.5,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => renderName(params),
  },
]

export const applicationBasedAnomaliesColumn = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "time",
    headerName: "Time",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "userName",
    headerName: "User Name",
    flex: 2,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => RenderUserName(params),
  },
]