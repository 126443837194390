import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect, BrowserRouter as Router } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./GovernorNavbar";
import "./GovernorSettings.scss"
import GovernorDashboard from "./GovernorDashboard";
import RiskPilot from "../../views/RiskPilot/RiskPilot";
import { RISK } from "../../../redux/constants/constants";
import NoPermissionView from "../../views/NoPermission/NoPermissionView";
import ProfileEditing from "../../views/ProfileEditing/ProfileEditing";
import CreateUser from "../../views/CreateUser/CreateUser";
import UserManagement from "../../views/UserMangement/UserManagement";
import EditUsers from "../../views/EditUser/EditUsers";

export default function GovernorContainer({match}) {
  const [expand, setExpand] = useState(true);
  const permissions = useSelector((state) => state.applicationData.permissions);
  const featuresData = JSON.parse(localStorage.getItem("featuresInfo"));
  const features =
    featuresData &&
    featuresData.features &&
    featuresData.features.map((data) => data.name);

  const handleShrink = () => {
    setExpand(!expand);
  };
  useEffect(() => {
    localStorage.setItem("project", "Governor");

    return () => {
      localStorage.setItem("mdrCurrentUser", "");
      localStorage.setItem("project", "");
    };
  }, []);

  return (
    <>
      <div className="wrapper governor-container">
        <Sidebar
          routes={[]}
          bgColor={null}
          expand={expand}
          handleShrink={handleShrink}
        />
        <div
          className={expand ? "main-panel" : "main-panel shrunk"}
        >
          <Navbar />
            <Switch>
              <Route exact path="/gov/GOVdashboards">
                <GovernorDashboard/>
              </Route>
              {permissions && permissions.includes("ADD_TENANT_USER") && (
              <Route
                exact
                path={`/gov/userManagement/createUsers`}
                component={CreateUser}
              />
            )}
            {permissions && permissions.includes("VIEW_TENANT_USER") && (
              <Route
                exact
                path={`/gov/userManagement`}
                component={UserManagement}
              />
            )}
            {permissions && permissions.includes("UPDATE_TENANT_USER") && (
              <Route
                exact
                path={`/gov/userManagement/editUser/:id`}
                component={EditUsers}
              />
            )}
              <Route path={`/gov/profileEditing`} component={ProfileEditing} />
              <Route path={`/gov/RiskPilot`} component={features && features.includes(RISK) ? RiskPilot : NoPermissionView}/>
            </Switch>
        </div>
      </div>
    </>
  )
};