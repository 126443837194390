import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_SYS_GUARD_ALERT_INFO_DATA,
  CLEAR_SYS_GUARD_POPUP_DATA,
  GET_SYS_GUARD_DISTINCT_LOCATIONS,
  GET_SYS_GUARD_POPUP_DATA,
  GET_SYS_GUARD_SIDE_POPUP_DATA,
  sysGuardTableColumnMappings,
} from "../sysGuardConstants";
import UserLocationPopup from "./UserLocationPopup";
import { userApplicationsColumn } from "./SysGuardPopupColumns";

function ManagePopup({ popupDetails, popupOpen, setPopupOpen }) {
  const { popupData, popupLocation } = popupDetails;
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const dispatch = useDispatch();
  
  const tableColumns = () => sysGuardTableColumnMappings[popupLocation] || userApplicationsColumn;

  const {
    sysGuardDataSource,
    sysGuardPopupData,
    popupTotalElements,
    loader,
    sidePopupData,
  } = useSelector((state) => state.SysGuardReducer);

  useEffect(() => {
    if (!popupLocation || !popupData || !sysGuardDataSource) return;

    const payload = {
      dataSourceId: sysGuardDataSource.id,
      actorId: popupData?.userId,
      loc: popupLocation,
      country: popupData?.countryName,
      application: popupData?.applicationName
    };

    if (popupLocation === "userLocations") {
      dispatch({ type: GET_SYS_GUARD_DISTINCT_LOCATIONS, payload });
    }


    dispatch({
      type: GET_SYS_GUARD_POPUP_DATA,
      payload: {
        ...payload,
        currentPage: pagination.page,
        pageSize: pagination.pageSize,
      },
    });

    if( popupLocation !== "locationBasedUsers") {
      dispatch({ type: GET_SYS_GUARD_SIDE_POPUP_DATA, payload });
    }

  }, [popupLocation, popupData, sysGuardDataSource, pagination]);
  

  return (
    <UserLocationPopup
      popupOpen={popupOpen}
      popupData={popupData}
      sysGuardPopupData={sysGuardPopupData}
      onClose={() => {
        setPopupOpen(false);
        dispatch({ type: CLEAR_SYS_GUARD_ALERT_INFO_DATA });
        dispatch({ type: CLEAR_SYS_GUARD_POPUP_DATA });
      }}
      totalElements={popupTotalElements}
      pagination={pagination}
      setPagination={setPagination}
      loader={loader}
      tableColumns={tableColumns}
      sidePopupData={sidePopupData}
      loc={popupLocation}
    />
  );
}

export default ManagePopup;
