import React from "react";
import "./SysGuard.scss";
import TimeSelector from "./Dashboard/Components/TimeSelector";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Content_Paths } from "./sysGuardConstants";
import SysGuardIncognito from "./SysGuardIncognito";

function SysGuardHeader() {
  const location = useLocation();
  const history = useHistory();
  const renderSYSGuardHeader = () => {
    return (
      <div className="sys-guard-header-container">
        <div className="riskGovernanceHeaderContainer">
          <div>
            <h2
              className={
                Content_Paths.includes(location.pathname)
                  ? "sysGuardHeaderTitle content"
                  : "sysGuardHeaderTitle"
              }
              onClick={() =>
                Content_Paths.includes(location.pathname) &&
                history.push("/mdr/sysguard")
              }
            >
              SysGuard Overview
            </h2>
          </div>
        </div>
        <div>
          <TimeSelector />
        </div>
        <SysGuardIncognito loc="overView"/>
      </div>
    );
  };
  return <div>{renderSYSGuardHeader()}</div>;
}

export default SysGuardHeader;
