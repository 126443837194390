import { put, takeLatest, select, call, cancel } from "redux-saga/effects";
import { GET_DATABASE_LOGS_URL ,GET_DATABASE_LOGS_COUNT_URL, LOADING_DATA, MASKED_DATA, GET_DATABASE_LOGS_NO_EXEC_URL} from "../constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";

function* getParamData() {
  let data = yield select();
  const {
    finalDataSourceName,
    finalDataSourceType,
    finalDatabaseAccessRoles,
    finalPolicyNames,
    finalSensitivityInfo,
    finalSortingValue,
    finalOrderByValue,
    alertId,
    finalFromTimeValue,
    finalToTimeValue,
    finalUsers,
    finalQueryStatement,
    finalClientIp,
    currentPage,
    recordsPerPage,
    location
  } = data.databaseLogsReducer;
  
  let paramData = {};
  paramData.page = currentPage;
  paramData.size = recordsPerPage;
  if (finalDataSourceType && finalDataSourceType.length > 0) {
    paramData.dataSourceType = finalDataSourceType;
  }
  if (finalDatabaseAccessRoles && finalDatabaseAccessRoles.length > 0) {
    paramData.databaseAccessRoles = finalDatabaseAccessRoles;
  }
  if (finalPolicyNames && finalPolicyNames.length > 0) {
    paramData.alertPolicyId = finalPolicyNames;
  }
  if (finalSensitivityInfo && finalSensitivityInfo.length > 0) {
    paramData.sensitivityLevel = finalSensitivityInfo;
  }
  if (finalDataSourceName && finalDataSourceName.length > 0) {
    paramData.dataSource = finalDataSourceName;
  }
  if (finalSortingValue && finalSortingValue !== "") {
    paramData.sortBy = finalSortingValue;
  }
  if(finalOrderByValue && finalOrderByValue !== ""){
    paramData.direction = finalOrderByValue;
  }
  if(alertId && alertId !== ""){
    paramData.alertId = alertId;
  }
  if(finalFromTimeValue && finalFromTimeValue !== ""){
    paramData.fromTimeStamp = finalFromTimeValue
  }
  if(finalToTimeValue && finalToTimeValue !== ""){
    paramData.toTimeStamp = finalToTimeValue
  }
  if(finalUsers && finalUsers.length > 0){
    // append to query string user list
    paramData.username = finalUsers;
  }
  if(finalQueryStatement && finalQueryStatement.length > 0){
    // append to query string
    paramData.queryContains = finalQueryStatement;
  }
  if(finalClientIp && finalClientIp.length > 0){
    // append to query string
    paramData.clientIps = finalClientIp;
  }
  if(location && location.length > 0) {
    paramData.location = location
  }
  return paramData;
}

/**
 * Process logs and set initial no of exec to loading
 * @param {*} dbLogs logs
 * @returns db logs with loading
 */
function processDBLogs(dbLogs) {
  return dbLogs.map((dbLog) => {
    dbLog.noOfExecutions = MASKED_DATA;
    return dbLog;
  });
}

/**
 * Function to get number of executions
 * @param {*} sqlIds SQL statement IDs
 */
function* getNoOfExecution(action) {
  // when user clicks on show icon
  // check if its for DB logs table
  // If so check if user has already clicked or if its first time for that page
  // If first time we can make API call or else ignore
  if (action && action.payload && action.payload.loc === "database-logs") {
    const timeToCall = new Date().getTime();
    let data = yield select();
    const {
      allDatabaseLogs,
      finalFromTimeValue,
      finalToTimeValue,
      showNoOfExec,
    } = data.databaseLogsReducer;
    //if show no. of exec is once per page if already clicked done do anything
    if (!showNoOfExec && allDatabaseLogs){
      yield put({ type: "SET_SHOW_NO_EXEC_TRUE"});
      // if we have logs data get SQL statement IDs and get no of exec
      const sqlIds = allDatabaseLogs.map((row) => {
        return row.sqlStatementId;
      });
      let paramData = {};
      if(finalFromTimeValue && finalFromTimeValue !== ""){
        paramData.fromTimeStamp = finalFromTimeValue
      }
      if(finalToTimeValue && finalToTimeValue !== ""){
        paramData.toTimeStamp = finalToTimeValue
      }
      const options = {
        method: "POST",
        url: `${GET_DATABASE_LOGS_NO_EXEC_URL}`,
        headers: getAxiosHeaders(true),
        params: paramData,
        data: {sqlStatementIds: sqlIds},
        timeout: 1200000,
      };
      const res = yield call(getService, options);
      if (res && res.status === 200 ) {
        let data = yield select();
        const {
          logsUpdateTimeStamp
        } = data.databaseLogsReducer;
        if (logsUpdateTimeStamp < timeToCall) {
          // if logs are loaded after this API that means its stale data user has clicked on next page
          yield put({ type: "SET_DATABASE_STMT_NO_EXEC" ,payload : { noOfExecutionsList : res.data}});
        }
      }
    }
  }
}

function* databaseLogsApi() {
  let data = yield getParamData();
  let options;
  options = {
    method: "POST",
    url: `${GET_DATABASE_LOGS_URL}`,
    headers: getAxiosHeaders(true),
    params: {},
    data: data,
    timeout: 1200000,
  };
  try {
    const res = yield call(getService, options);
    if (res && res.status === 200 ) {
      yield put({ type: "SET_DATABASE_INFO" ,payload : { allDatabaseLogs : processDBLogs(res.data.content) , totalCount:res.data.totalElements}});
    } else {
      yield put({ type: "SET_LOADER_INFO" ,payload:false});
    }
  } catch (error) {
    yield put({ type: "SET_LOADER_INFO" ,payload:false});
  }
}

function* databaseLogsCountApi() {
  const timeToCall = new Date().getTime();
  let data = yield getParamData();
  let options = {
    method: "POST",
    url: `${GET_DATABASE_LOGS_COUNT_URL}`,
    headers: getAxiosHeaders(true),
    params: {},
    data: data,
    timeout: 1200000,
  };
  try {
    const res = yield call(getService, options);
    let data = yield select();
    const {
      freshLoadTimestamp
    } = data.databaseLogsReducer;
    if (freshLoadTimestamp < timeToCall) {
      // update count to store only if logs are not changed or updated
      yield put({ type: "SET_DATABASE_COUNT" ,payload : {  totalCount : res.data}});
    }
  } catch (error) {
    //ignore if error
  }
}

function* databaseLogsSaga() {
  yield takeLatest("GET_DATABASE_LOGS_INFO_DATA", databaseLogsApi);
  yield takeLatest("GET_DATABASE_LOGS_COUNT", databaseLogsCountApi);
  yield takeLatest("LOAD_VALUE_TBL_COLUMN", getNoOfExecution);
}

export default databaseLogsSaga;