import React, { useState, useEffect } from "react";
import { ColoredActionButton } from "../common/buttons";
import { Button } from "@material-ui/core";
import ErrorIcon from '../../assets/icons/ErrorIcon.svg';
import ConnectedStatusOD from "../../assets/icons/ConnedtedStatusOD.svg"
import NotConnected from "../../assets/icons/NotConnected.svg"

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Operation from "antd/lib/transfer/operation";
import { Graph2d } from "vis";
import { useLocation } from "react-router-dom";
import { ENTRA } from "../../redux/constants/constants";
import { isSysGuard } from "../../features/SysGuard/sysGuardConstants";

const OPERATIONS = {
  GRAPH: 'GRAPH',
  MANAGEMENT: 'MANAGEMENT'
}

export const OneDriveConnection = (props) => {
  const cancelErrorInfo = "Something went wrong, Please try again";
  const currentRoute = useLocation();
  const graphToolTip = 'Access to users, roles, sites, files data through the Microsoft Graph API'
  const managementToolTip = 'Access to activity data through the Office 365 Management API'
  const { onHandleConnect, dataSourceData, engine } = props;
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [error, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [errorOcurred, setErrorOcurred] = useState(false);
  const dispatch = useDispatch();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    const urlParam = new URLSearchParams(currentRoute.search);
    const error_description = urlParam.get('error_description');
    if (!isEmpty(error_description)) {
      setErrorOcurred(true);
    }
  }, [currentRoute])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLOSE_SNACKBAR" });
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const getButtonText = (secretKey) => {
    if ((dataSourceData && dataSourceData[secretKey])) {
      return "Connect";
    } else if (dataSourceData.id) {
      return "Connect"
    } else {
      return "Verify & Connect"
    }
  }

  const graphButton = () => {
    return getButtonText("secret1");
  }

  const managementButton = () => {
    return getButtonText("secret3");
  }

  const getConnectedStatus = () => {
    return (
      <div className="one-drive-status-icon oneDriveStatus">
        <img src={ConnectedStatusOD} />
      </div>
    )
  };

  const getNotConnectedStatus = () => {
    return (
      <div className="one-drive-status-icon oneDriveStatus">
        <img src={NotConnected} />
      </div>
    )
  };

  const getErrorStatus = (errorInfo) => {
    return (
      <div className="one-drive-status-icon oneDriveStatus">
        <img src={ErrorIcon} />
      </div>
    )
  };

  const getSnackBarStatus = (secretKey) => {
    //If we receive any error from backend 
    if (dataSourceData && isNull(dataSourceData[secretKey]) && !isNull(dataSourceData.errorInfo)) {
      return getErrorStatus(dataSourceData.errorInfo);
    }
    //If we receive any error from Microsoft 365 
    if (dataSourceData && isNull(dataSourceData[secretKey]) && isNull(dataSourceData.errorInfo) && errorOcurred) {
      return getErrorStatus(cancelErrorInfo);
    }
    //To see if the Api's are connected or not
    if (dataSourceData && dataSourceData[secretKey]) {
      return getConnectedStatus();
    } else if (dataSourceData && isNull(dataSourceData[secretKey])) {
      return getNotConnectedStatus();
    }
  }

  return (
    <>
      <div className="one-drive-buttons">
        <div className="connection-group  graph-api">
          <div className="one-drive-status-group">
            <div className="one-drive-status-icon" title={graphToolTip}>
              Access to Users and Files
            </div>
            <div className="oneDriveConnectionIconContainer">
              {getSnackBarStatus("secret1")}
              <Button
                type="submit"
                className={
                  engine === ENTRA && isSysGuard()
                    ? "sys-guard"
                    : "one-drive-button-style"
                }
                variant="contained"
                color="primary"
                onClick={(event) =>
                  onHandleConnect(event, OPERATIONS.GRAPH, dispatch)
                }
              >
                {graphButton()}
              </Button>
            </div>
          </div>
        </div>
        {engine === ENTRA ? null : (
          <div className="connection-group management-api">
            <div className="one-drive-status-group">
              <div className="one-drive-status-icon" title={managementToolTip}>
                Access to Audit Logs
              </div>
              <div className="oneDriveConnectionIconContainer">
                {getSnackBarStatus("secret3")}
                <Button
                  type="submit"
                  className="one-drive-button-style"
                  variant="contained"
                  color="primary"
                  onClick={(event) =>
                    onHandleConnect(event, OPERATIONS.MANAGEMENT, dispatch)
                  }
                >
                  {managementButton()}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="button-group">
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          onExited={handleExited}
        >
          <Alert onClose={handleClose} severity={"error"}>
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}