import React from "react";
import { GaugeComponent } from "react-gauge-component";

function GaugeChart({ value, gaugeColor }) {
  const subarc = [];

  // Add the first subArc for the actual value (user's data point)
  subarc.push({ limit: value, color: "#f43860" });

  // To avoid duplicate limit values, only add additional subArcs if value < 100
  if (value < 100) {
    // Add a tiny gap (0.1) after the actual value to create a visual separation
    // This prevents conflicts where two adjacent subArcs have the same limit
    subarc.push({ limit: value + 0.1, color: "white", height: 0.3 });

    //Fill the rest of the gauge up to 100 with the base gauge color
    subarc.push({ limit: 100, color: gaugeColor });
  }

  return (
    <div className="sys-guard-gauge-container">
      <GaugeComponent
        type="semicircle"
        value={value}
        pointer={{ hide: true }}
        arc={{
          width: 0.35,
          cornerRadius: 2,
          padding: 0,
          subArcs: subarc,
        }}
        labels={{
          tickLabels: { hideMinMax: true },
          valueLabel: { formatTextValue: () => `${value}%` },
        }}
      />
    </div>
  );
}

export default GaugeChart;
