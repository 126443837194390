import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  MapContainer,
  TileLayer,
  Circle,
  LayerGroup,
  Tooltip,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { GET_SYS_GUARD_CITY_DATA } from "../../sysGuardConstants";

// Default map settings
const defaultCenter = [22, 78];

// Component to update map view dynamically
const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const SysGuardCountryMap = ({ mapData, countryCode }) => {
  const dispatch = useDispatch();

  // Get processed city data and map center from Redux state
  const cityCircles = useSelector((state) => state.SysGuardReducer.cities);
  const mapCenter = useSelector((state) => state.SysGuardReducer.mapCenter) || defaultCenter;

  useEffect(() => {
    // Dispatch action to fetch city data via saga
    dispatch({
      type: GET_SYS_GUARD_CITY_DATA,
      payload: { mapData, countryCode },
    });
  }, [mapData, countryCode, dispatch]);

  return (
    <div className="sys-guard-map-parent-container">
      <MapContainer
        center={mapCenter}
        zoom={6}
        minZoom={4}
        maxZoom={12}
        className="sys-guard-country-map-container"
        zoomControl={false}
        scrollWheelZoom
        dragging
        touchZoom={false}
        worldCopyJump={false}
        doubleClickZoom={false}
        maxBounds={[
          [-85, -170],
          [85, 170],
        ]}
      >
        <ChangeView center={mapCenter} zoom={6} />
        <TileLayer
          url="https://cartodb-basemaps-a.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
          noWrap={true}
        />
        {/* Layer Group for Circles */}
        <LayerGroup>
          {cityCircles.map((city, index) => (
            <Circle
              key={index}
              center={[city.lat, city.lng]}
              radius={Math.max(city.users * 1000, 3000)}
              pathOptions={{
                color: "blue",
                fillColor: "blue",
                fillOpacity: 0.3,
              }}
            >
              <Tooltip direction="top" offset={[0, 0]} opacity={1} permanent>
                <strong>{city.name}: {city.users}</strong> <br />
              </Tooltip>
            </Circle>
          ))}
        </LayerGroup>
      </MapContainer>
    </div>
  );
};

export default SysGuardCountryMap;
