import { combineReducers } from "redux";
import getAlertsPoliciesReducer from "./getAlertsPoliciesReducer";
import tokenReducer from "./tokenReducer";
import { signInPageReducer } from "./signInPageReducer";
import { deletePolicyReducer } from "./deletePolicyReducer";
import { updateAlertPolicyReducer } from "./updatePolicyReducer";
import { createAlertPolicyReducer } from "./createAlertPolicyReducer";
import { updateAlertPolicyStatusReducer } from "./updateAlertPolicyStatusReducer";
import { changeAlertStatusReducer } from "./alertStatusChangeReducer";
import { signUpPageReducer } from "./signupPageReducer";
import { setPasswordReducer } from "./setPasswordReducer";
import { platformAdminTenantOnBoardReducer } from "./platformAdminTenantOnBoardReducer";
import { deleteTenantReducer } from "./deleteTenantReducer";
import { getApplicationsReducer } from "./getApplicationsReducer";
import { datasourceDataReducer } from "./datasourceReducer";
import { dropBoxSuccessReducer } from "./dropBoxSuccessReducer";
import { selectedItemsReducer } from "./selectedItemsReducer";
import { editSensitivityReducer } from "./editSensitivityReducer";
import { selectedNodeReducer } from "./selectedNodeReducer";
import { selectedDateTimeReducer } from "./selectedDateTimeRangeReducer";
import { userAccessRIghtsReducer } from "./userAccessRIghtsReducer";
import { trackTraversalReducer } from "./trackTraversalReducer";
import { selectedTimeZoneReducer } from "./selectedTimeZoneReducer";
import { AccessDetailsReducer } from "./AccessDetailsReducer";
import { IncidenetReducer } from "./IncidenetReducer";
import { IncidenetTableFilterReducer } from "./incidentFilterReducer";
import { InspectContentReducer } from "./InspectContentReducer";
// import { MdrAuthenticationFilterReducer } from "./MdrAuthenticationFilterReducer";
import { MDRAuthenticationReducer } from "./MDRAuthenticationReducer";
import { MDREndPointsReducer } from "./MDREndPointsReducer";
import { MDRPatchMonitoringReducer } from "./MDRPatchMonitoringReducer";
import { MDREndPointsFilterReducer } from "./MDREndPointsFilterReducer";
import { nodePositionsReducer } from "./nodePositionsReducer";
import { resetPasswordReducer } from "./resetPasswordReducer";
import { alertCountReducer } from "./alertCountReducer";
import { databaseLogsReducer } from "./databaseLogsReducer";
import { normalDataSourceReducer } from "./NormalDatasourceReducer";
import { normalDirectDataSourceReducer } from "./directDataAccessReducer";
import { serviceDataAccessReducer } from "./serviceDataAccessReducer"
import { mdrSettingsReducer } from "./mdrSetttingsReducer";
import { flaggedDataSourceReducer } from "./FlaggedDatasourceReducer";
import {serviceAccessLogsReducer} from "./serviceAccessLogsReducer"
import {dataMapReducer} from "./dataMapReducer";
import { allAccessReducer } from "./allAccessReducer";
import { heatmapReducer } from "./heatmapReducer";
import { DataOwnerReducer } from "./DataOwnerReducer";
import { TotalAssetsCountReducer } from "./TotalAssetsCountReducer";
import { GenerateReportReducer } from "./GenerateReportReducer";
import { DeleteConnectionReducer } from "./DeleteConnectionReducer";
import { TenantProfileReducer } from "./TenantProfileReducer";
import { CreateSourceReducer } from "./createSourceReducer";
import { NetspaceReducer } from "../../features/NetSpace/NetSpaceReducer";
import { riskPilotReducer } from "../../components/views/RiskPilot/reducers/riskPilotReducer";
import { threatReducer } from "../../components/views/RiskPilot/reducers/threatReducer";
import { vulnerabilityReducer } from "../../components/views/RiskPilot/reducers/vulnerabilityReducer";
import { mitigationReducer } from "../../components/views/RiskPilot/reducers/mitigationReducer";
import { tenantSignUpReducer } from "../../components/views/TenantSignUp/tenantSignUpReducer";
import { ntfsReducer } from "../../components/views/NTFS/ntfsReducer";
import { locationReducer } from "../../components/views/manageDataMap/reducer/locationReducer";
import { SysGuardReducer } from "../../features/SysGuard/reducer/SysGuardReducer";
const rootReducer = combineReducers({
  getAlertsPolicies: getAlertsPoliciesReducer,
  token: tokenReducer,
  signInpage: signInPageReducer,
  policyDeleted: deletePolicyReducer,
  policyUpdated: updateAlertPolicyReducer,
  policyCreated: createAlertPolicyReducer,
  policyStatusUpdated: updateAlertPolicyStatusReducer,
  alertStatusChanged: changeAlertStatusReducer,
  selfSignUppage: signUpPageReducer,
  setPasswordReducer: setPasswordReducer,
  tenantCreate: platformAdminTenantOnBoardReducer,
  tenantDeleted: deleteTenantReducer,
  applicationData: getApplicationsReducer,
  datasourceData: datasourceDataReducer,
  dropBoxSuccessReducer: dropBoxSuccessReducer,
  selectedItemsData: selectedItemsReducer,
  sensitivityUpdate: editSensitivityReducer,
  allAccessData: allAccessReducer,
  selectedNode: selectedNodeReducer,
  selectedDateTimeRange: selectedDateTimeReducer,
  userAccessRightsReducer: userAccessRIghtsReducer,
  tracks: trackTraversalReducer,
  timeZone: selectedTimeZoneReducer,
  accessDetails: AccessDetailsReducer,
  IncidenetReducer: IncidenetReducer,
  IncidenetTableFilterReducer: IncidenetTableFilterReducer,
  InspectContentReducer: InspectContentReducer,
  // MdrAuthenticationFilterReducer: MdrAuthenticationFilterReducer,
  MDRAuthenticationReducer: MDRAuthenticationReducer,
  MDREndPointsReducer: MDREndPointsReducer,
  MDRPatchMonitoringReducer: MDRPatchMonitoringReducer,
  MDREndPointsFilterReducer: MDREndPointsFilterReducer,
  nodePositions: nodePositionsReducer,
  resetPasswordReducer: resetPasswordReducer,
  alertCount: alertCountReducer,
  databaseLogsReducer: databaseLogsReducer,
  normalDataSourceReducer: normalDataSourceReducer,
  normalDirectDataSourceReducer: normalDirectDataSourceReducer,
  serviceDataAccessReducer:serviceDataAccessReducer,
  mdrSettingsReducer: mdrSettingsReducer,
  flaggedDataSourceReducer: flaggedDataSourceReducer,
  serviceAccessLogsReducer:serviceAccessLogsReducer,
  dataMapReducer:dataMapReducer,
  heatmapReducer:heatmapReducer,
  DataOwnerReducer: DataOwnerReducer,
  GenerateReportReducer: GenerateReportReducer,
  TotalAssetsCountReducer: TotalAssetsCountReducer,
  //For LogSource and DataSource Deletion
  DeleteConnectionReducer:DeleteConnectionReducer,
  TenantProfileReducer:TenantProfileReducer,
  NetspaceReducer:NetspaceReducer,
  //For Data Source creation
  CreateSourceReducer: CreateSourceReducer,
  riskPilotReducer: riskPilotReducer,
  threatReducer: threatReducer,
  vulnerabilityReducer: vulnerabilityReducer,
  mitigationReducer: mitigationReducer,
  tenantSignUpReducer: tenantSignUpReducer,
  ntfsReducer: ntfsReducer,
  locationReducer: locationReducer,
  SysGuardReducer: SysGuardReducer,
});

export default rootReducer;
