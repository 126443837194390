import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import { ApplyButton } from "../common/buttons";

export default function QueryStatementComponent(props) {
  let typeParam = "queryStatement";
  let placeholder = "Type Query";
  if (props.type && props.type === "clientIp") {
    typeParam = "clientIp";
    placeholder = "Type IP Address";
  }
  const { DatabaseLogsClickHandler } = props;
  const [queryStatement, setQueryStatement] = useState('');
  const trimmedQueryString = () => {
    return queryStatement.trim();
  }
  const onHandleClick = () => {
    const TrimmedValue = trimmedQueryString();
    if (TrimmedValue.length > 0) {
      DatabaseLogsClickHandler(queryStatement, typeParam);
      setQueryStatement('');
    }
  };
  
  return (
    <div>
      <InputBase
        style={{ borderBottom: "2px solid gray", width: "100%" }}
        placeholder={placeholder}
        value={queryStatement}
        onChange={(event) => {
          setQueryStatement(event.target.value);
        }}
      />
      <ApplyButton
        style={{ padding: "10px 20px", marginTop: "10px" }}
        onClick={onHandleClick}
        disabled={trimmedQueryString().length === 0}
      >
        Ok
      </ApplyButton>
    </div>
  );
}
