import { isEmpty, isNull } from "lodash";
import { ACCOUNT_NAME_ERROR_MESSAGE, ALIAS_NAME_ERROR_MESSAGE, DB_NAME_ERROR_MESSAGE, DESIRED_HOST_ERROR_MESSAGE, DOMAIN_NAME_ERROR_MESSAGE, DROPBOX, ENTRA, GOOGLEDRIVE, HOST_ERROR_MESSAGE, INSTANCE_ERROR_MESSAGE, MSSQL, NTFS_DIRECTORIES_ERROR_MESSAGE, NTFS_DIRECTORIES_NOT_ALLOWED_ERROR_MESSAGE, NTFS_HOST_ERROR_MESSAGE, NTFS_PROCESS_NAMES_NOT_ALLOWED_ERROR_MESSAGE, ONEDRIVE, OTHER, PASSWORD_ERROR_MESSAGE, PORT_ERROR_MESSAGE, USER_NAME_ERROR_MESSAGE, selectiveOneDriveUsersErrorMessage } from "../../../redux/constants/constants";
import { applicationNameValidation, isCloudFileSystem, isMSSQLDatabaseOnCloud } from "../../common/utilities";
import { NTFS } from "./dataSourceConstants";

// Function to validate username and password
export const validateUsernameAndPassword = (engine, username, password) => {
    const errors = {};
    // Check if the engine is not "OTHER" and not a cloud file system
    if (engine !== OTHER && !isCloudFileSystem(engine) && engine !== NTFS) {
      if (isEmpty(username) || isEmpty(username.trim())) {
        errors.username = USER_NAME_ERROR_MESSAGE;
      }
      if (isEmpty(password) || isEmpty(password.trim())) {
        errors.password = PASSWORD_ERROR_MESSAGE;
      }
    }
    return errors;
  };

  export const validateHostAndPort = (engine, address, port) => {
    const errors = {};
    if (engine === MSSQL) {
      if((isEmpty(address) || isEmpty(address.trim()))) {
        errors.address = HOST_ERROR_MESSAGE;
      }
      if((isEmpty(port.toString()) || isEmpty(port.toString().trim()))) {
        errors.port = PORT_ERROR_MESSAGE;
      }
    }
    return errors;
  }
  
  // Function to validate instance and host
  export const validateInstanceAndHost = (engine, instance, desiredPort, address, desiredHost) => {
    const errors = {};
    // Check if the engine is not "OTHER" and not a cloud file system
    if (engine !== OTHER && !isCloudFileSystem(engine) && engine !== NTFS) {
      // Validate instance and port
      if ((isEmpty(instance) || isEmpty(instance.trim())) &&
          (isNull(desiredPort) || isEmpty(desiredPort.toString().trim()))) {
        errors.instance = INSTANCE_ERROR_MESSAGE;
      }
      // Validate address and desired host
      if ((isEmpty(address) || isEmpty(address.trim())) &&
          (isEmpty(desiredHost) || isEmpty(desiredHost.trim()))) {
        errors.desiredHost = DESIRED_HOST_ERROR_MESSAGE;
      }
    }
    return errors;
  };
  
  // Function to validate database name
 export const validateDBName = (engine, DBName) => {
    const errors = {};
    // Check if it's not a cloud file system and DBName is empty
    if (!isCloudFileSystem(engine) && engine !== ENTRA && (isEmpty(DBName) || isEmpty(DBName.trim()))) {
      errors.DBName = DB_NAME_ERROR_MESSAGE;
    }
    return errors;
  };
  
  // Function to validate application name
 export const validateApplicationName = (applicationName) => {
    // Perform validation logic for application name
    const applicationNameError = applicationNameValidation(applicationName);
    // If there is an error, return an object with the error message
    return !isEmpty(applicationNameError) ? { applicationName: applicationNameError } : {};
  };

  export const validateNTFSHost = (engine, ntfsHost) => {
    const errors = {};
    if (engine === NTFS && (isEmpty(ntfsHost) || isEmpty(ntfsHost.trim()))) {
      errors.ntfsHost = NTFS_HOST_ERROR_MESSAGE;
    }
    return errors;
  };

  export const validateNTFSDirectories = (engine, ntfsDirectories) => {
    const errors = {};
    const QUOTES_REGEX = /['"]/;

    if (engine !== NTFS) {
      return errors;
    }

    for (const dir of ntfsDirectories) {
      if (QUOTES_REGEX.test(dir)) {
        errors.ntfsDirectories = NTFS_DIRECTORIES_NOT_ALLOWED_ERROR_MESSAGE;
        break;
      }
    }

    return errors;
  };
  

  export const validateNTFSProcessNames = (engine, ntfsProcess) => {
    const errors = {};
    const QUOTES_REGEX = /['"]/;

    if (engine !== NTFS) {
      return errors;
    }

    for (const process of ntfsProcess) {
      if (QUOTES_REGEX.test(process)) {
        errors.ntfsProcessNames = NTFS_PROCESS_NAMES_NOT_ALLOWED_ERROR_MESSAGE;
        break;
      }
    }

    return errors;
  };
  
  
  
  // Function to validate domain name based on engine type
  export const validateDomainName = (engine, domainName) => {
    const errors = {};
    // Check if domain name is required for the engine type
    if (isEmpty(domainName) || isEmpty(domainName.trim())) {
      // Additional validation based on engine type
      if (engine === ONEDRIVE || engine === GOOGLEDRIVE || engine === ENTRA) {
        errors.domainName = DOMAIN_NAME_ERROR_MESSAGE;
      } else if (engine === DROPBOX) {
        errors.accountName = ACCOUNT_NAME_ERROR_MESSAGE;
      }
    }
    return errors;
  };
  
  // Function to validate alias name
  export const validateAliasName = (aliasName) => {
    // Perform validation logic for alias name
    return isEmpty(aliasName) || isEmpty(aliasName.trim()) ? { aliasName: ALIAS_NAME_ERROR_MESSAGE } : {};
  };
  
  // Function to validate cloudSource user count based on engine type and user limit
 export const validateOneDriveUserCount = (engine, limitUsers, cloudSourceUsers) => {
    const errors = {};
    // Check if cloudSource user count is required based on engine type and user limit
    if (engine === ONEDRIVE && limitUsers && (isEmpty(cloudSourceUsers.toString().trim()) || cloudSourceUsers === 0)) {
      // Validate cloudSource user count
      errors.oneDriveUserCount = selectiveOneDriveUsersErrorMessage;
    }
    return errors;
  };