import ErrorIcon from "../../assets/icons/ErrorIcon.svg";
import ConnectedStatusOD from "../../assets/icons/ConnedtedStatusOD.svg";
import NotConnected from "../../assets/icons/NotConnected.svg";
import { domain_vizr } from "../../redux/constants/constants";
import {
  applicationBasedAnomaliesColumn,
  applicationBasedUsersColumn,
  locationBasedAnomaliesColumn,
  locationBasedApplicationColumn,
  locationBasedUsersColumn,
  userAnomaliesColumn,
  userDeviceColumns,
  userLocationColumns,
} from "./Popups/SysGuardPopupColumns";
import { isEmpty, isNull } from "lodash";
export const SYS_GUARD = "mdr-sysGuard";
export const SET_ALIAS_NAME = "SET_ALIAS_NAME";
export const SET_APPLICATION_NAME = "SET_APPLICATION_NAME";
export const SET_DOMAIN_NAME = "SET_DOMAIN_NAME";
export const SET_HOST_TYPE = "SET_HOST_TYPE";
export const SET_ENGINE = "SET_ENGINE";
export const SET_APPLICATION_NAME_LIST = "SET_APPLICATION_NAME_LIST";
export const RESET_SYS_GUARD = "RESET_SYS_GUARD";
export const FETCH_SYS_GUARD_DATA_SOURCE = "FETCH_SYS_GUARD_DATA_SOURCE";
export const SET_SYS_GUARD_DATASOURCE = "SET_SYS_GUARD_DATASOURCE";
export const SET_SYS_FROM_TIME_STAMP = "SET_SYS_FROM_TIME_STAMP";
export const SET_SYS_TO_TIME_STAMP = "SET_SYS_TO_TIME_STAMP";
export const SET_SYS_GUARD_TABLE_DATA = "SET_SYS_GUARD_TABLE_DATA";
export const CLEAR_SYS_GUARD_DATA = "CLEAR_SYS_GUARD_DATA";
export const SET_SYS_GUARD_PAGE_LOADER = "SET_SYS_GUARD_PAGE_LOADER";
export const GET_SYS_GUARD_USERS = "GET_SYS_GUARD_USERS";
export const GET_SYS_GUARD_LOCATION = "GET_SYS_GUARD_LOCATION";
export const GET_SYS_GUARD_APPLICATION = "GET_SYS_GUARD_APPLICATION";
export const SET_TOTAL_ELEMENTS = "SET_TOTAL_ELEMENTS";
export const GET_SYS_GUARD_HEADER_DATA = "GET_SYS_GUARD_HEADER_DATA";
export const SET_SYS_GUARD_HEADER_DATA = "SET_SYS_GUARD_HEADER_DATA";
export const GET_SYS_GUARD_POPUP_DATA = "GET_SYS_GUARD_POPUP_DATA";
export const SET_SYS_GUARD_POPUP_DATA = "SET_SYS_GUARD_POPUP_DATA";
export const SET_SYS_GUARD_POPUP_TOTAL_ELEMENTS =
  "SET_SYS_GUARD_POPUP_TOTAL_ELEMENTS";
export const SET_SYS_GUARD_SIDE_POPUP_DATA = "SET_SYS_GUARD_SIDE_POPUP_DATA";
export const GET_SYS_GUARD_SIDE_POPUP_DATA = "GET_SYS_GUARD_SIDE_POPUP_DATA";
export const SET_SYS_GUARD_DISTINCT_LOCATIONS =
  "SET_SYS_GUARD_DISTINCT_LOCATIONS";
export const GET_SYS_GUARD_DISTINCT_LOCATIONS =
  "GET_SYS_GUARD_DISTINCT_LOCATIONS";
export const GET_LOCATION_BASED_SYS_GUARD_USERS =
  "GET_LOCATION_BASED_SYS_GUARD_USERS";
export const GET_SYS_GUARD_CITY_DATA = "GET_SYS_GUARD_CITY_DATA";
export const SET_SYS_GUARD_CITY_DATA = "SET_SYS_GUARD_CITY_DATA";
export const SET_INCOGNITO = "SET_INCOGNITO";
export const CLEAR_SYS_GUARD_POPUP_DATA = "CLEAR_SYS_GUARD_POPUP_DATA";
export const CLEAR_SYS_GUARD_TABLE_DATA = "CLEAR_SYS_GUARD_TABLE_DATA";
export const MFALookUp = {
  true: "YES",
  false: "No",
};
export const GraphToolTip =
  "Access to users, roles, sites, files data through the Microsoft Graph API";
export const statusMapping = {
  Connected: ConnectedStatusOD,
  "Not Connected": NotConnected,
  Error: ErrorIcon,
};
export const isSysGuard = () => {
  const location = window.location.pathname;
  return location === "/mdr/sysguard";
};
export const getDatasourceWithType = (type) => {
  return `${domain_vizr}/tenant/dataSourceType/${type}`;
};
export const fetchSysGuardUsersAPI = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  currentPage,
  pageSize
) => {
  return `${domain_vizr}/sysguard/users?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&page=${currentPage}&size=${pageSize}`;
};
export const fetchSysGuardLocationData = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  currentPage,
  pageSize
) => {
  return `${domain_vizr}/sysguard/locations?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&page=${currentPage}&size=${pageSize}`;
};

export const fetchSysGuardApplicationAPI = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  pageSize,
  currentPage
) => {
  return `${domain_vizr}/sysguard/applications?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&page=${currentPage}&size=${pageSize}`;
};

export const fetchSysGuardUserLocationApi = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId,
  pageSize,
  currentPage
) => {
  return `${domain_vizr}/sysguard/user/locationSummary?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&actorId=${actorId}&page=${currentPage}&size=${pageSize}`;
};

export const fetchSysGuardUserDeviceApi = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId,
  pageSize,
  currentPage
) => {
  return `${domain_vizr}/sysguard/user/deviceSummary?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&actorId=${actorId}&page=${currentPage}&size=${pageSize}`;
};

export const fetchSysGuardUserAnomaliesApi = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId,
  pageSize,
  currentPage
) => {
  return `${domain_vizr}/sysguard/user/anomalies?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&actorId=${actorId}&page=${currentPage}&size=${pageSize}`;
};

export const fetchSysGuardUserApplicationApi = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId,
  pageSize,
  currentPage
) => {
  return `${domain_vizr}/sysguard/user/appsSummary?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&actorId=${actorId}&page=${currentPage}&size=${pageSize}`;
};

export const fetchSidePopupData = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId,
  location
) => {
  return `${domain_vizr}/sysguard/user/counts?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&actorId=${actorId}&groupBy=${location}`;
};

export const fetchUserBasedDevicesCount = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId
) => {
  return `${domain_vizr}/sysguard/user/deviceSummaryCount?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&actorId=${actorId}`
}

export const fetchUserAnomaliesSidePopupData = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId
) => {
  return `${domain_vizr}/sysguard/user/anomaliesCount?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&actorId=${actorId}`;
};

export const fetchSysGuardUserDistinctLocations = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId
) => {
  return `${domain_vizr}/sysguard/user/distinctLocations?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&actorId=${actorId}`;
};

export const fetchSysGuardLocationBasedUsers = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId,
  pageSize,
  currentPage,
  country
) => {
  return `${domain_vizr}/sysguard/location/userSummary?toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&fromTimeStamp=${fromTimeStamp}&country=${country}&page=${currentPage}&size=${pageSize}`;
};

export const fetchSysGuardApplicationBasedUsers = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId,
  pageSize,
  currentPage,
  country,
  application
) => {
  return `${domain_vizr}/sysguard/application/userSummary?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&appName=${application}&page=${currentPage}&size=${pageSize}`;
};

export const fetchSysGuardApplicationBasedAnomalies = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId,
  pageSize,
  currentPage,
  country,
  application
) => {
  return `${domain_vizr}/sysguard/application/anomalies?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&appName=${application}&page=${currentPage}&size=${pageSize}`;
};

export const fetchSysGuardLocationBasedAnomalies = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId,
  pageSize,
  currentPage,
  country
) => {
  return `${domain_vizr}/sysguard/location/anomalies?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&country=${country}&page=${currentPage}&size=${pageSize}`;
};

export const fetchSysGuardLocationBasedApplications = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  actorId,
  pageSize,
  currentPage,
  country
) => {
  return `${domain_vizr}/sysguard/location/applications?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&country=${country}&page=${currentPage}&size=${pageSize}`;
};

export const fetchApplicationBasedUserCounts = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  application
) => {
  return `${domain_vizr}/sysguard/application/userCount?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&appName=${application}`
}

export const fetchLocationAnomaliesCount = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  country
) => {
  return `${domain_vizr}/sysguard/location/anomaliesCount?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&country=${country}`;
};

export const fetchLocationBasedApplications = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  country
) => {
  return `${domain_vizr}/sysguard/user/counts?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&country=${country}&groupBy=application`;
};

export const fetchApplicationAnomaliesCount = (
  fromTimeStamp,
  toTimeStamp,
  dataSourceId,
  application
) => {
  return `${domain_vizr}/sysguard/application/anomaliesCount?fromTimeStamp=${fromTimeStamp}&toTimeStamp=${toTimeStamp}&dataSourceId=${dataSourceId}&appName=${application}`
}

export const fetchCityCoordinates = (city, countryCode) => {
  return `https://nominatim.openstreetmap.org/search?city=${city}&countrycodes=${countryCode}&format=json`;
}

export const calculatePercentage = (part, total) => {
  if (!total || total === 0) return 0;
  const percentage = (part / total) * 100;
  return percentage === 0 ? 0 : Math.round(percentage);
};

export const Content_Paths = [
  "/mdr/sysguard/content/Users",
  "/mdr/sysguard/content/Locations",
  "/mdr/sysguard/content/Applications",
];

export const countryWithFlagTableLocations = [
  "locationBasedUsers",
  "locationBasedAnomalies",
  "locationBasedApplications",
];
export const titleWithCounts = [
  "locationBasedApplications",
  "locationBasedUsers",
  "userApplications",
  "userLocations",
  "applicationBasedUsers"
];
export const applicationPopupMainTitleLocations = [
  "applicationBasedUsers",
  "applicationBasedAnomalies",
];
export const customBarChatLocations = [
  "locationBasedApplications",
  "userApplications",
];
export const sysGuardPopupTitles = {
  userLocations: "Location Based Access",
  userDevices: "Devices By User",
  userApplications: "Applications",
  locationBasedUsers: "Location - Users",
  locationBasedAnomalies: "Location - Anomalies",
  locationBasedApplications: "Location - Applications",
  applicationBasedUsers: "Application - Users",
  applicationBasedAnomalies: "Application - Anomalies",
};
export const sysGuardTitleNumbers = {
  userLocations: "noOFLocations",
  userDevices: "noOfDeviceAnomalies",
  userAnomalies: "noOfAnomalies",
  locationBasedUsers: "numberOfUsers",
  applicationBasedUsers: "numberOfUsers",
  locationBasedAnomalies: "locationAnomalies",
  locationBasedApplications: "applicationInUse",
  applicationBasedAnomalies: "applicationAnomalies",
};
export const sysGuardSubHeadings = {
  userLocations: "Access Location:",
  userApplications: "User Applications:",
  locationBasedUsers: "Users:",
  locationBasedAnomalies: "Anomalies:",
  locationBasedApplications: "Application:",
  applicationBasedUsers: "Users: "
};
export const sysGuardTableColumnMappings = {
  userLocations: userLocationColumns,
  userDevices: userDeviceColumns,
  userAnomalies: userAnomaliesColumn,
  locationBasedUsers: locationBasedUsersColumn,
  locationBasedAnomalies: locationBasedAnomaliesColumn,
  locationBasedApplications: locationBasedApplicationColumn,
  applicationBasedUsers: applicationBasedUsersColumn,
  applicationBasedAnomalies: applicationBasedAnomaliesColumn,
};
export const threatLevelLookup = {
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
  NONE: "No Risk"
}

export const SET_SYS_GUARD_ALERT_INFO_DATA = "SET_SYS_GUARD_ALERT_INFO_DATA";
export const CLEAR_SYS_GUARD_ALERT_INFO_DATA = "CLEAR_SYS_GUARD_ALERT_INFO_DATA";
export const sysGuardAlertInfoDataColumns = ["locationBasedAnomalies", "userAnomalies", "applicationBasedAnomalies"];

export const maskedUsernameLoc= ["locationBasedAnomalies", "applicationBasedUsers", "applicationBasedAnomalies"];

export const unknownUser = (value, isIncognito) => {
  return isEmpty(value) || isNull(value) ? "Unknown" : value;
}

export const largeMapMarkers = ["userLocation", "dashboardPopup"]